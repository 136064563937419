/** @format */

import React, { useState, useEffect } from "react";
import BottomBar from "../components/BottomBar.jsx";
import "flowbite";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useNavigate, useLocation } from "react-router-dom";
import * as routes from "../constants/routeNames.js";
import { checkUserAuth, verificationAndAuth } from "../actions/auth.js";
import { useDispatch } from "react-redux";

// import { isDragActive } from "framer-motion";

const SessionSettings = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const OnLoadData = location.state?.OnLoadData;
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));

  const [votingMethodIsOpen, setVotingMethodIsOpen] = useState(false);
  const changeVotingMethodState = () => {
    setVotingMethodIsOpen(!votingMethodIsOpen);
  };

  const [isBookmarkSaved, setIsBookmarkSaved] = useState(false);
  const toggleBookmarkSaved = () => {
    setIsBookmarkSaved(!isBookmarkSaved);
  };

  const [settings, setSettings] = useState(
    OnLoadData
      ? OnLoadData
      : {
          selectedVotingMethod: "Dot voting",
          numberOfRounds: 7,
          ideasPerRound: 3,
          timePerRound: 5,
          anonymity: false,
        }
  );

  // useEffect(() => {
  //   if (OnLoadData) {
  //     setSettings(OnLoadData);
  //   }
  // }, [OnLoadData]);
  useEffect(() => {
    dispatch(verificationAndAuth(navigate));
  }, []);
  const selectVotingMethod = (method) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      selectedVotingMethod: method,
    }));
    setVotingMethodIsOpen(false);
  };

  const handleChange = (name, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const toggleAnonymity = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      anonymity: !prevSettings.anonymity,
    }));
  };

  const applyPressed = () => {
    console.log(settings);
    navigate(routes.createSession, { state: { OnLoadData: settings } });
  };

  const sliderStyle = {
    trackStyle: [{ backgroundColor: "#E0752D", height: 8 }],
    railStyle: { backgroundColor: "#F7D1B7", opacity: 10, height: 8 },
    handleStyle: [
      {
        backgroundColor: "white",
        opacity: 100,
        borderColor: "#E0752D",
        height: 24,
        width: 24,
        top: 2,
      },
    ],
  };

  return (
    <div className="bg-white md:bg-profile">
      <div className="block md:hidden">
        <div className="h-full text-xlheader">
          <div className="flex justify-between items-start">
            <div className="mt-28 ml-5">
              <h1 className="text-2xl font-semibold w-[220px] text-left text-orange900">
                Brainwriting Session Settings
              </h1>
            </div>
            <img
              src={
                isBookmarkSaved
                  ? "https://brainwrite-assets.s3.amazonaws.com/bookmrk-saved.svg"
                  : "https://brainwrite-assets.s3.amazonaws.com/bookmark-unfilled.svg"
              }
              alt="bookmark"
              className="mt-28 mr-5 hover:cursor-pointer"
              onClick={toggleBookmarkSaved}
            />
          </div>
        </div>

        <div className="flex-col justify-center mt-10 mx-5">
          <div className="flex justify-between items-center border-2 border-graybrown400 rounded-2xl py-3 mx-auto max-w-[400px] mb-6">
            <p className="ml-5 font-medium text-base text-orange900">
              Anonymity
            </p>
            <label className="flex justify-between items-center mr-5">
              <input
                type="checkbox"
                className="mr-5 appearance-none peer invisible"
                checked={settings.anonymity}
                onChange={toggleAnonymity}
              ></input>
              <span className="hover:cursor-pointer w-12 h-7 flex items-center flex-shrink-0  bg-graybrown400 rounded-full p-1 after:w-5 after:h-5 after:bg-white after:rounded-full after:shadow-md peer-checked:bg-orange500 duration-300 ease-in-out after:duration-300 peer-checked:after:translate-x-5"></span>
            </label>
          </div>

          <div
            className={`hover:cursor-pointer flex justify-between items-center border-2 rounded-2xl py-3 mx-auto max-w-[400px] mb-6 ${
              !votingMethodIsOpen ? "block" : "hidden"
            }`}
            onClick={changeVotingMethodState}
          >
            <p className="ml-5 font-medium text-base text-orange900">
              Voting method: {settings.selectedVotingMethod}
            </p>
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/doublearrow.svg"
              alt="double arrow"
              className="flex justify-between items-center mr-5 hover:cursor-pointer"
            />
          </div>

          <div
            className={`hover:cursor-pointer flex-col border-2 rounded-2xl py-3 mx-auto max-w-[400px] mb-6 ${
              !votingMethodIsOpen ? "hidden" : "block"
            }`}
            onClick={changeVotingMethodState}
          >
            <div className="flex justify-between items-center">
              <p className="ml-5 font-medium text-base text-orange900">
                Voting method: {settings.selectedVotingMethod}
              </p>
              <img
                src="https://brainwrite-assets.s3.amazonaws.com/doublearrow.svg"
                alt="double arrow"
                className="flex justify-between items-center mr-5 hover:cursor-pointer"
              />
            </div>
            <hr className="mt-2" />
            <div className="hover:bg-orange200 p-1">
              <p
                className="text-left ml-5 text-sm text-orange900"
                onClick={() => selectVotingMethod("Dot voting")}
              >
                Dot voting
              </p>
            </div>
            <div
              className="hover:bg-orange200 p-1"
              onClick={() => selectVotingMethod("Random sampling")}
            >
              <p className="text-left ml-5 text-sm text-orange900">
                Random sampling
              </p>
            </div>
          </div>

          {/* small screens */}
          <div className="flex-col">
            <div className="mb-8">
              <label
                htmlFor="numberOfRounds"
                className="flex font-medium ml-1 text-base text-orange900 mb-1"
              >
                Number of Rounds
              </label>
              <Slider
                id="numberOfRounds"
                min={1}
                max={8}
                value={settings.numberOfRounds}
                onChange={(value) => handleChange("numberOfRounds", value)}
                {...sliderStyle}
              />
              <span className="flex font-medium text-graybrown400 ml-1 text-base mt-1">
                {settings.numberOfRounds} Rounds
              </span>
            </div>

            <div className="mb-8">
              <label
                htmlFor="ideasPerRound"
                className="flex font-medium ml-1 text-base"
              >
                <span className="inline-block mb-1">
                  Ideas per Round (per Participant)
                </span>
              </label>
              <Slider
                id="ideasPerRound"
                min={1}
                max={3}
                value={settings.ideasPerRound}
                onChange={(value) => handleChange("ideasPerRound", value)}
                {...sliderStyle}
              />
              <span className="flex font-medium text-graybrown400 ml-1 text-base mt-1">
                {settings.ideasPerRound} Ideas
              </span>
            </div>

            <div className="mb-8">
              <label
                htmlFor="timePerRound"
                className="flex font-medium ml-1 text-base mb-1"
              >
                Time per Round
              </label>
              <Slider
                id="timePerRound"
                min={1}
                max={20}
                value={settings.timePerRound}
                onChange={(value) => handleChange("timePerRound", value)}
                {...sliderStyle}
              />
              <span className="flex font-medium text-graybrown400 ml-1 text-base mt-1">
                {settings.timePerRound} Minutes
              </span>
            </div>
          </div>

          <div className="flex-col">
            <button
              className="flex justify-center bg-orange100 drop-shadow-custom mx-auto rounded-lg py-2 w-[335px] mb-6 text-base font-semibold hover:bg-orange500 hover:text-white"
              onClick={applyPressed}
            >
              Apply
            </button>

            <button className="flex justify-center border-2 bg-white border-graybrown400 mx-auto rounded-lg py-2 w-[160px] mb-6 text-base font-semibold hover:bg-graybrown150 hover:text-graybrown600">
              View Presets
            </button>
          </div>
        </div>
      </div>

      {/* medium screen session settings */}
      <div className="hidden md:block overflow-hidden">
        <div className="flex justify-center mx-5">
          <div className="flex-col mt-28 mb-8 p-5 w-full rounded-lg bg-white max-w-[800px]">
            <div className="flex justify-between items-start">
              <div className="ml-5">
                <h1 className="text-2xl font-semibold w-[220px] text-left text-orange900">
                  Brainwriting Session Settings
                </h1>
              </div>
              <img
                src={
                  isBookmarkSaved
                    ? "https://brainwrite-assets.s3.amazonaws.com/bookmrk-saved.svg"
                    : "https://brainwrite-assets.s3.amazonaws.com/bookmark-unfilled.svg"
                }
                alt="bookmark"
                className="mr-5 hover:cursor-pointer"
                onClick={toggleBookmarkSaved}
              />
            </div>

            <div className="flex-col justify-center mt-10 mx-5">
              <div className="flex justify-between items-center border-2 rounded-2xl py-3 mx-auto w-full mb-6">
                <p className="ml-5 font-medium text-base text-orange900">
                  Anonymity
                </p>
                <label className="flex justify-between items-center mr-5">
                  <input
                    type="checkbox"
                    className="mr-5 appearance-none peer invisible"
                    checked={settings.anonymity}
                    onChange={toggleAnonymity}
                  ></input>
                  <span className="hover:cursor-pointer w-12 h-7 flex items-center flex-shrink-0  bg-graybrown400 rounded-full p-1 after:w-5 after:h-5 after:bg-white after:rounded-full after:shadow-md peer-checked:bg-orange500 duration-300 ease-in-out after:duration-300 peer-checked:after:translate-x-5"></span>
                </label>
              </div>

              <div
                className={`hover:cursor-pointer flex justify-between items-center border-2 rounded-2xl py-3 mx-auto w-full mb-6 ${
                  !votingMethodIsOpen ? "block" : "hidden"
                }`}
                onClick={changeVotingMethodState}
              >
                <p className="ml-5 font-medium text-base text-orange900">
                  Voting method: {settings.selectedVotingMethod}
                </p>
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/doublearrow.svg"
                  alt="double arrow"
                  className="flex justify-between items-center mr-5 hover:cursor-pointer"
                />
              </div>

              <div
                className={`hover:cursor-pointer flex-col border-2 rounded-2xl py-3 mx-auto w-full mb-6 ${
                  !votingMethodIsOpen ? "hidden" : "block"
                }`}
                onClick={changeVotingMethodState}
              >
                <div className="flex justify-between items-center">
                  <p className="ml-5 font-medium text-base text-orange900">
                    Voting method: {settings.selectedVotingMethod}
                  </p>
                  <img
                    src="https://brainwrite-assets.s3.amazonaws.com/doublearrow.svg"
                    alt="double arrow"
                    className="flex justify-between items-center mr-5 hover:cursor-pointer"
                  />
                </div>
                <hr className="mt-2" />
                <div className="hover:bg-orange200 p-1">
                  <p
                    className="text-left ml-5 text-sm text-orange900"
                    onClick={() => selectVotingMethod("Dot voting")}
                  >
                    Dot voting
                  </p>
                </div>
                <div
                  className="hover:bg-orange200 p-1"
                  onClick={() => selectVotingMethod("Random sampling")}
                >
                  <p className="text-left ml-5 text-sm text-orange900">
                    Random sampling
                  </p>
                </div>
              </div>

              <div className="flex-col">
                <div className="mb-8">
                  <label
                    htmlFor="numberOfRounds"
                    className="flex font-medium ml-1 text-base text-orange900 mb-1"
                  >
                    Number of Rounds
                  </label>
                  <Slider
                    id="numberOfRounds"
                    min={1}
                    max={8}
                    value={settings.numberOfRounds}
                    onChange={(value) => handleChange("numberOfRounds", value)}
                    {...sliderStyle}
                  />
                  <span className="flex font-medium text-graybrown400 ml-1 text-base mt-1">
                    {settings.numberOfRounds} Rounds
                  </span>
                </div>

                <div className="mb-8">
                  <label
                    htmlFor="ideasPerRound"
                    className="flex font-medium ml-1 text-base"
                  >
                    <span className="inline-block mb-1">
                      Ideas per Round (per Participant)
                    </span>
                  </label>
                  <Slider
                    id="ideasPerRound"
                    min={1}
                    max={3}
                    value={settings.ideasPerRound}
                    onChange={(value) => handleChange("ideasPerRound", value)}
                    {...sliderStyle}
                  />
                  <span className="flex font-medium text-graybrown400 ml-1 text-base mt-1">
                    {settings.ideasPerRound} Ideas
                  </span>
                </div>

                <div className="mb-8">
                  <label
                    htmlFor="timePerRound"
                    className="flex font-medium ml-1 text-base mb-1"
                  >
                    Time per Round
                  </label>
                  <Slider
                    id="timePerRound"
                    min={1}
                    max={20}
                    value={settings.timePerRound}
                    onChange={(value) => handleChange("timePerRound", value)}
                    {...sliderStyle}
                  />
                  <span className="flex font-medium text-graybrown400 ml-1 text-base mt-1">
                    {settings.timePerRound} Minutes
                  </span>
                </div>
              </div>

              <div className="flex w-full">
                <button
                  className="flex justify-center bg-orange100 drop-shadow-custom mr-5 rounded-lg py-2 mb-6 w-full text-base font-semibold hover:bg-orange500 hover:text-white"
                  onClick={applyPressed}
                >
                  Apply
                </button>

                <button className="flex justify-center border-2 bg-white border-graybrown400 mx-auto rounded-lg py-2 mb-6 w-[160px] text-base font-semibold hover:bg-graybrown150 hover:text-graybrown600">
                  View Presets
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionSettings;
