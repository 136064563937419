/** @format */

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkUserAuth } from "../actions/auth.js";
import { LOGOUT } from "../constants/actionTypes.js";
import * as routes from "../constants/routeNames.js";
import { createPortal } from "react-dom";

const colors = [
  { light: "FDC4C4", dark: "BA3B3B" }, // red
  { light: "EBFFF8", dark: "0C7A56" }, // green
  { light: "EBFFFF", dark: "0C437A" }, // blue
  { light: "ffd6c1", dark: "cf6c00" }, // orange
  { light: "F8EBFF", dark: "560C7A" }, // purple
  { light: "ffffda", dark: "b9b900" }, // yellow
  { light: "f1cffe", dark: "e146da" }, // pink
  { light: "0C7A56", dark: "EBFFF8" }, // dark-green
  { light: "BA3B3B", dark: "FDC4C4" }, // dark-red
  { light: "0C437A", dark: "EBFFFF" }, // dark-blue
  { light: "df7c10", dark: "fdd6a1" }, // dark-orange
  { light: "560C7A", dark: "F8EBFF" }, // dark-purple
  { light: "b9b900", dark: "ffffda" }, // dark-yellow
  { light: "e146da", dark: "f1cffe" }, // dark-pink
];

const getInitials = (name) => {
  const names = name.split(" ");
  return names
    .map((n) => n[0])
    .slice(0, 3)
    .join("")
    .toUpperCase();
};

const ParticipantAvatar = ({ user, size = 10 }) => {
  const [imageError, setImageError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const avatarRef = useRef(null);
  const colorIndex = user.colorIndex % colors.length;
  const { light, dark } = colors[colorIndex];
  const initials = getInitials(user.fullName);

  const handleImageError = () => {
    setImageError(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (avatarRef.current) {
      const rect = avatarRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX + rect.width / 2,
      });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // tooltip
  const Tooltip = ({ children, isVisible }) => {
    if (!isVisible) return null;
    return createPortal(
      <div
        className="fixed z-50 text-xs font-medium rounded-md shadow-lg p-1 max-w-[200px] min-w-[80px] text-center"
        style={{
          backgroundColor: `#${light}`,
          color: `#${dark}`,
          position: 'absolute',
          top: `${tooltipPosition.top + 4}px`,
          left: `${tooltipPosition.left}px`,
          transform: 'translateX(-50%)',
          width: 'max-content',
          whiteSpace: 'normal',
          wordBreak: 'keep-all',
          overflowWrap: 'break-word',
        }}
      >
        {children}
      </div>,
      document.body
    );
  };

  return (
    <>
      <div
        ref={avatarRef}
        className="relative flex flex-col items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={`w-10 h-10 rounded-full flex items-center justify-center relative`}
          style={
            !user.profilePhoto || imageError
              ? { backgroundColor: `#E0752D`, border: `1px solid #FFFFFFF` }
              : { border: `1px solid #FFFFFFF` }
          }
        >
          {!user.profilePhoto || imageError ? (
            <span style={{ color: `#FFFFFF` }} className="text-base font-normal">
              {initials}
            </span>
          ) : (
            <img
              src={user.profilePhoto}
              alt={initials}
              className="w-full h-full rounded-full object-cover"
              onError={handleImageError}
            />
          )}
        </div>
      </div>
    </>
  );
};

const AvatarOrange = ({ user, size = 10 }) => {
  const [imageError, setImageError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const avatarRef = useRef(null);
  const colorIndex = user.colorIndex % colors.length;
  const { light, dark } = colors[colorIndex];
  const initials = getInitials(user.fullName);

  const handleImageError = () => {
    setImageError(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (avatarRef.current) {
      const rect = avatarRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX + rect.width / 2,
      });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // tooltip
  const Tooltip = ({ children, isVisible }) => {
    if (!isVisible) return null;
    return createPortal(
      <div
        className="fixed z-50 text-xs font-medium rounded-md shadow-lg p-1 max-w-[200px] min-w-[80px] text-center"
        style={{
          backgroundColor: `#E0752D`,
          color: `#FFFFFF`,
          position: 'absolute',
          top: `${tooltipPosition.top + 4}px`,
          left: `${tooltipPosition.left}px`,
          transform: 'translateX(-50%)',
          width: 'max-content',
          whiteSpace: 'normal',
          wordBreak: 'keep-all',
          overflowWrap: 'break-word',
        }}
      >
        {children}
      </div>,
      document.body
    );
  };

  return (
    <>
      <div
        ref={avatarRef}
        className="relative flex flex-col items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={`w-10 h-10 rounded-full flex items-center justify-center relative`}
          style={
            !user.profilePhoto || imageError
              ? { backgroundColor: `#E0752D`, border: `1px solid #FFFFFF` }
              : { border: `2px solid #FFFFFF` }
          }
        >
          {!user.profilePhoto || imageError ? (
            <span style={{ color: `#FFFFFF` }} className="text-base font-normal">
              {initials}
            </span>
          ) : (
            <img
              src={user.profilePhoto}
              alt={initials}
              className="w-full h-full rounded-full object-cover"
              onError={handleImageError}
            />
          )}
        </div>
      </div>
      <Tooltip isVisible={isHovered}>
        {user.fullName}
      </Tooltip>
    </>
  );
};


const ParticipantsBox = ({ participants, option = 1 }) => {
  //   participants = [
  //     {
  //       emaiL: "jimbo@gmail.com",
  //       fullName: "Rob Glazer",
  //       profilePhoto:
  //         "https://brainwrite-users.s3.us-east-1.amazonaws.com/profilePhotos/1724275226188_Screenshot%202024-07-31%20at%209.17.03%C3%A2%C2%80%C2%AFPM.png",
  //       colorIndex: 0,
  //     },
  //     {
  //       emaiL: "shlimbo@gmail.com",
  //       fullName: "Shlimbo",
  //       profilePhoto: "www.shleb.coom",
  //       colorIndex: 1,
  //     },
  //     {
  //       emaiL: "flimbo@gmail.com",
  //       fullName: "John Deer",
  //       profilePhoto: "",
  //       colorIndex: 2,
  //     },
  //     {
  //       emaiL: "grimbo@gmail.com",
  //       fullName: "Juju Smith Schuster",
  //       profilePhoto:
  //         "https://brainwrite-users.s3.us-east-1.amazonaws.com/profilePhotos/1724360373588_Brainwrite%20Logo%20PF%201.png",
  //       colorIndex: 3,
  //     },
  //     {
  //       emaiL: "trimbo@gmail.com",
  //       fullName: "Esteban Julio Ricardo Montoya de la Rosa Ramírez",
  //       profilePhoto:
  //         "https://brainwrite-users.s3.us-east-1.amazonaws.com/profilePhotos/1724360373588_Brinwrite%20Logo%201.png",
  //       colorIndex: 4,
  //     },
  //     {
  //       emaiL: "wimbo@gmail.com",
  //       fullName: "Walluigi",
  //       profilePhoto:
  //         "https://news.mit.edu/sites/default/files/styles/news_article__image_gallery/public/images/201011/20101123131216-1_0.jpg?itok=ma_P1FSF",
  //       colorIndex: 5,
  //     },
  //     {
  //       emaiL: "himbo@gmail.com",
  //       fullName: "h j",
  //       profilePhoto:
  //         "https://news.mit.edu/sites/default/files/styles/news_article__image_gallery/public/images/201011/20101123131216-1_0.jpg?itok=ma_P1FSF",
  //       colorIndex: 6,
  //     },
  //     {
  //       emaiL: "quimbo@gmail.com",
  //       fullName: "quimbo juggins",
  //       profilePhoto: "",
  //       colorIndex: 7,
  //     },
  //     {
  //       emaiL: "crimbo@gmail.com",
  //       fullName: "crimbo juggins",
  //       profilePhoto: "xcvdskjnvdkajn",
  //       colorIndex: 8,
  //     },
  //     {
  //       emaiL: "blimbo@gmail.com",
  //       fullName: "John Henry McCalister",
  //       profilePhoto: "",
  //       colorIndex: 9,
  //     },
  //   ];

  if (option == 3) {
    return (

      <div className="flex flex-col gap-2 items-start w-full h-full">
        <div className="w-full text-orange900 font-medium text-base text-left md:text-right">SESSION PARTICIPANTS</div>
        <div className="flex md:flex-col justify-center items-center md:hidden select-none">
          {participants &&
            participants.map((user, index) => (
              <div key={index} className={index !== 0 ? "-ml-2" : ""}>
                <AvatarOrange user={user} size={10} />
              </div>
            ))}
        </div>
        <div className="hidden md:flex md:flex-col justify-center items-end w-full select-none">
          {participants &&
            participants.map((user, index) => (
              <div key={index} className={index !== 0 ? "-mt-2" : ""}>
                <AvatarOrange user={user} size={10} />
              </div>
            ))}
        </div>
      </div>)
  }
  else if (option == 2) {
    return (
      <div className="bg-white rounded-2xl h-full w-full flex flex-col overflow-hidden min-w-[200px] max-w-[50vw]">
        <div className="bg-white w-full flex flex-row items-center justify-center gap-1 z-30 drop-shadow-custom2 py-4 sticky top-0">
          <img
            src="https://brainwrite-assets.s3.amazonaws.com/partic.svg"
            alt="participants icon"
            className="w-3 h-3"
          />
          <h2 className="text-xs font-normal">Session Participants</h2>
        </div>
        <div className="flex-grow overflow-y-auto w-full">
          <div className="flex flex-col items-start gap-4 p-4">
            {participants &&
              participants.map((user, index) => (
                <div key={index} className={`flex items-center gap-4`}>
                  <ParticipantAvatar user={user} size={10} />
                  <div className="font-normal text-xs text-orange900 select-text">{user.fullName}</div>
                </div>
              ))}
          </div>
        </div>
      </div>)
  }
  else {
    return (
      <div className="bg-profile drop-shadow-custom w-auto h-auto md:h-[95px] rounded-3xl flex flex-col items-center justify-between mt-12 p-4">
        <div className="flex flex-row gap-1">
          <img
            src="https://brainwrite-assets.s3.amazonaws.com/partic.svg"
            alt="participants icon"
            className="w-3 h-3"
          ></img>
          <h2 className="text-xs font-normal">Session Participants</h2>
        </div>
        <div className="flex justify-center items-center ">
          {participants &&
            participants.map((user, index) => (
              <div key={index} className={index !== 0 ? "-ml-2" : ""}>
                <ParticipantAvatar user={user} size={10} />
              </div>
            ))}
        </div>
      </div>
    );
  }
};

export default ParticipantsBox;
