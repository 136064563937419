/** @format */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  sendVerification,
  verifyToken,
  verificationAndAuth,
  checkUserAuth,
  checkVerification,
} from "../../actions/auth";
import * as routes from "../../constants/routeNames.js";

const VerifyEmail = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [email, setEmail] = useState("");

  // useEffect(() => {
  //   // dispatch(verificationAndAuth(user, navigate));
  // }, []);
  useEffect(() => {
    const verifyUser = async () => {
      if (user?.result?._id) {
        try {
          const data = await dispatch(checkVerification(user, navigate, true));
          if (data?.verified) {
            console.log("Verified");
            navigate(routes.beginHere);
          }
        } catch (error) {
          console.error("Error during verification:", error);
        }
      }
    };
    // console.log("Gonna verify user");
    verifyUser();
  }, [dispatch, navigate, user]);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    const verifyEmailToken = async () => {
      if (token) {
        try {
          const res = await dispatch(verifyToken(user, navigate, { token }));
          setMessage(res?.message || "Email verified successfully.");
        } catch (error) {
          setMessage("Verification failed. Please try again.");
        }
      }
    };

    verifyEmailToken();
  }, [location, dispatch, user]);
  useEffect(() => {
    if (user?.result?.email) {
      setEmail(user.result.email);
    }
  }, []);

  const handleCodeSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(verifyToken(user, navigate, { code }));
      setMessage(res?.message || "Code verified successfully.");
    } catch (error) {
      setMessage("Invalid code. Please try again.");
    }
  };

  const sendCode = async () => {
    try {
      console.log("Sending code. You are: ", user);
      const res = await dispatch(sendVerification(user));
      setMessage(res?.message || "Verification code sent.");
    } catch (error) {
      setMessage("Unable to resend code. Please try again.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full px-5 overflow-x-hidden relative bg-profile">
      <div className="flex flex-col w-full h-full bg-white items-start border-[0.6px] border-graybrown400 max-w-[520px] pb-12 rounded-lg">
        <div className="px-5 w-full">
          <h1 className="text-2xl font-normal mt-10 text-left">
            Check your email
          </h1>
        </div>
        <hr className="w-full h-[1px] bg-graybrown400 border-0 mt-2" />
        <hr className="w-[208px] h-[1px] bg-orange500 border-0 -mt-[1px] ml-5" />
        <div className="px-5 w-full">
          <label className="text-left block mt-4 text-base mb-5 max-w-[360px]">
            A 6-digit confirmation code has been sent to{" "}
            <span className="font-bold">{email}</span>. Please enter the code
            below to verify your email address.
          </label>
          <form onSubmit={handleCodeSubmit}>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="w-full mt-2 py-2 border border-graybrown400 rounded-lg placeholder-graybrown400 px-3 mb-5"
              placeholder="Enter 6-digit code"
            />
          </form>
          <div className="mt-3 text-orange900 text-left text-xs">
            Didn't receive a code? <br className="block md:hidden"></br>
            <span
              onClick={sendCode}
              className="text-orange500 underline cursor-pointer"
            >
              Click here to send code again
            </span>
          </div>
          <div className="text-sm text-red-400 mt-2 text-left">{message}</div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
