/** @format */

// src/components/TopBar.js
import React from "react";
import { useNavigate } from "react-router-dom";
import * as routes from "../constants/routeNames.js";
function BottomBar() {
  const navigate = useNavigate();
  const privacyPolicyClicked = () => {
    navigate(routes.privacyPolicy);
  };
  return (
    <div className="bottom-bar">
      <div className="flex justify-between items-center px-8 py-8 md:py-6 w-full text-sm font-bold bg-orange900 drop-shadow-md">
        <div className="flex flex-col">
          <div className="os-semi-bold text-white text-[0.8rem]">
            Brainwrite.io © 2024
          </div>
        </div>
        <div
          className="text-white text-[0.8rem] cursor-pointer"
          onClick={privacyPolicyClicked}
        >
          Privacy Policy
        </div>

        <div className="flex items-center space-x-7 md:space-x-10">
          <div>
            <a
              href="https://www.producthunt.com/posts/brainwrite-io?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-brainwrite&#0045;io"
              target="_blank"
            >
              <img
                src="https://brainwrite-assets.s3.amazonaws.com/Product-Hunt.svg"
                alt="Brainwrite&#0046;io - Better&#0032;team&#0032;ideation | Product Hunt"
                className="cursor-pointer w-[16px] md:w-[25px]"
              />
            </a>
          </div>
          <a
            href="https://www.instagram.com/brainwrite.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/ig-icon.svg"
              alt="Instagram"
              className="cursor-pointer w-[16px] md:w-[25px]"
            />
          </a>
          <a
            href="https://x.com/brainwriteio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/x-icon.svg"
              alt="X"
              className="cursor-pointer w-[16px] md:w-[25px]"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/brainwrite-io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/linkedin-icon.svg"
              alt="LinkedIn"
              className="cursor-pointer w-[16px] md:w-[25px]"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BottomBar;
