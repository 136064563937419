/** @format */

import React, { useState, useEffect } from "react";
import BottomBar from "../../components/BottomBar.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";
import jwtDecode from "jwt-decode"; // Corrected import
import { useDispatch } from "react-redux";
import { signin, signup, googleAuth, checkUserAuth } from "../../actions/auth";
import * as routes from "../../constants/routeNames.js";

const serverUrl = `${process.env.REACT_APP_SERVER_BASE_URL}`;
const googleClientID = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`.trim();

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [profileResponse, setProfileResponse] = useState();
  const [formData, setFormData] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignUpClick = () => {
    navigate(routes.signup);
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();

    if (validateEmail(formData.email)) {
      if (formData.password) {
        setError("");
        handleSubmit();
      } else {
        handleError("Please enter a valid password.");
      }
    } else {
      handleError("Please enter a valid email address.");
    }
  };

  const handleError = (err) => {
    console.log(err);
    setError(err);
    setFormData({ ...formData, password: "" });
  };

  const handleSubmit = async () => {
    try {
      console.log(formData);
      await dispatch(signin(formData, navigate));
    } catch (error) {
      handleError(error.message || "An error occurred during login.");
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = tokenResponse?.access_token;
      try {
        const profile = await axios.get(
          "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = profile.data;
        console.log(result);
        dispatch(googleAuth(token, result, navigate));
      } catch (error) {
        handleError("Google login failed. Please try again.");
      }
    },
    onError: (error) => {
      handleError("Google login failed. Please try again.");
    },
  });
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("profile"));
        if (user && user.token) {
          const isAuthenticated = await dispatch(checkUserAuth(user.token));
          if (isAuthenticated) {
            navigate(routes.beginHere);
          }
        }
      } catch (error) {
        console.error("Error checking user authentication", error);
      }
    };

    checkAuthStatus();
  }, [dispatch, navigate]);

  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center flex-1 bg-white w-full max-w-md">
        <div className="w-full md:mt-10 sm:mt-5">
          <h1 className="text-2xl font-semibold text-center text-orange900 mb-10">
            Sign In
          </h1>
          <div className="flex flex-col align-middle justify-center items-center ">
            <GoogleOAuthProvider clientId={googleClientID}>
              <button
                onClick={googleLogin}
                className="w-[335px] xl:w-[400px] mx-auto py-2 text-orange900 font-regular bg-white border border-graybrown400 rounded-md hover:bg-orange100 text-lg flex items-center justify-center"
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/google-icon.svg"
                  alt="Google logo"
                  className="w-6 h-6 mr-2"
                />
                Sign in with Google
              </button>
            </GoogleOAuthProvider>
          </div>
          <div className="w-[335px] xl:w-[400px] mx-auto relative mt-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-graybrown400"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-orange900 font-bold">OR</span>
            </div>
          </div>
          <form
            className="w-[335px] xl:w-[400px] mx-auto mt-6 space-y-6"
            onSubmit={handleSubmitClick}
          >
            <div className="relative">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center mt-1 pointer-events-none">
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/email-icon2.svg"
                  alt="Email icon"
                  className="w-5 h-5 text-bwgray"
                />
              </div>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                className="w-full px-10 py-2 mt-1 border border-graybrown400 rounded focus:outline-none focus:ring-orange500 focus:border-orange500 font-medium placeholder-graybrown400 text-orange900"
                onChange={handleChange}
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/pword-icon.svg"
                  alt="Password icon"
                  className="w-5 h-5"
                />
              </div>
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                className="w-full px-10 py-2 mt-1 border border-graybrown400 rounded focus:outline-none focus:ring-orange500 focus:border-orange500 font-medium placeholder-graybrown400 text-orange900"
                onChange={handleChange}
                value={formData.password}
              />
              <div className="absolute inset-y-6 right-0 pr-3 flex items-center">
                <button
                  type="button"
                  className="text-sm font-medium text-orange500 hover:text-orange300 focus:outline-none"
                  onClick={() => alert("Forgot password clicked")}
                >
                  Forgot?
                </button>
              </div>
            </div>
            <div className="regular text-red-500 mb-[3vw] sm:mb-[3vw] md:mb-[2.5vw] lg:mb-[2vw] xl:mb-[1.5vw] text-xs sm:text-xs ready-to-transform-error">
              {error}
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="w-4 h-4 checked:bg-orange500 border-orange900 focus:ring-orange500"
                />
                <label
                  htmlFor="remember_me"
                  className="block ml-2 text-xs text-orange900"
                >
                  Remember me
                </label>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full px-4 py-3 text-white bg-orange500 rounded-3xl hover:bg-orange300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bwblue font-semibold"
              >
                Continue
              </button>
            </div>
          </form>
          <div className="mt-4 text-center mb-10">
            <span className="text-orange900 text-xs">
              Don't have an account?{" "}
            </span>
            <button
              type="button"
              className="font-medium text-orange500 underline hover:text-orange300 focus:outline-none text-xs"
              onClick={handleSignUpClick}
            >
              Sign up
            </button>
          </div>
        </div>
      </div>
      <div className="bottom-0 w-full">
        <BottomBar />
      </div>
    </div>
  );
};

export default Login;
