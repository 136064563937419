import React, { useEffect, useRef, useState } from "react";
import "flowbite";
import ParticipantsBox from "../components/ParticipantsBox.jsx";
import { getParticipants } from "../actions/rooms.js";
import { checkUserAuth } from "../actions/auth.js";
import { makeRoom, startRoom } from "../actions/rooms.js";
import { useDispatch } from "react-redux";

const Chrome = () => {
    const videoRef = useRef(null);
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);

    const [roomCode, setRoomCode] = useState(
        JSON.parse(localStorage.getItem("room"))?.roomCode
    );
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));

    const updateUsers = async () => {
        if (user && roomCode) {
            const participants = await dispatch(getParticipants(user, roomCode));
            console.log("Participants: ", participants);
            setUsers(participants);
        }
    };

    useEffect(() => {
        updateUsers();
    }, []);

    useEffect(() => {
        import("flowbite").then((flowbite) => {
            flowbite.initPopovers();
        });

        // Ensure video plays
        if (videoRef.current) {
            videoRef.current.play().catch(error => {
                console.error("Error attempting to play video: ", error);
            });
        }
    }, []);

    return (
        <div className="relative min-h-screen overflow-hidden pb-12 bg-profile">
            <div className="relative z-10 mx-auto px-5 md:px-20 pt-24 md:pt-36 flex flex-col md:flex-row items-start justify-around min-h-screen">
                <div className="flex flex-col gap-6 mb-6 md:mb-0 max-w-[228px] md:max-w-[273px]">
                    <div className="text-left text-orange900 text-base font-normal">
                        Waiting for all participants to submit their ideas
                    </div>
                    <div className="text-left text-orange900 text-base font-normal\">
                        <span className="text-orange500 font-bold">Next</span>, build on a teammate's idea or add more of your own!
                    </div>
                </div>

                <div className="w-full max-w-[80vw] lg:max-w-[45vw] self-center pb-12 mt-0 lg:-mt-[5vh]">
                    <div className="w-full flex items-center justify-center">
                        <video
                            ref={videoRef}
                            autoPlay
                            muted
                            loop
                            playsInline
                            className=""
                        >
                            <source src="https://brainwrite-assets.s3.amazonaws.com/waiting-animation.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

                <div className="min-w-[228px] md:min-w-[273px]">
                    <ParticipantsBox participants={users} option={3} />
                </div>
            </div>
        </div>
    );
};

export default Chrome;