/** @format */

import React, { useState, useRef, useEffect, lazy } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import BottomBar from "../components/BottomBar.jsx";
import "./BeginHere.css";
import { LOGOUT } from "../constants/actionTypes.js";
import { checkUserAuth, verificationAndAuth } from "../actions/auth.js";
import { getAllSavedRooms } from "../actions/savedRooms.js";
import * as routes from "../constants/routeNames.js";

// Updated savedRooms
// Grid item component

const GridItem = ({ title, date, id, onClick }) => {
  return (
    <div
      className="flex-shrink-0 flex-col h-[140px] bg-white hover:brightness-95 rounded-lg text-left shadow-md slide-item cursor-pointer min-w-[225px] w-[30vw] max-w-[300px] active:translate-y-1"
      onClick={() => onClick(id)}
    >
      <div className="flex flex-col h-full mx-[2vw] md:mx-[24px]">
        <div className="text-orange900 font-semibold mt-4 mb-1 text-base">
          {title}
        </div>
        <img
          src="https://brainwrite-assets.s3.amazonaws.com/beginherelines.svg"
          alt="divider"
          className="mb-1 mt-1 w-[120px] md:w-[160px]"
        />
        <div className="text-orange900 font-light text-xs md:text-base mb-[24px] mt-1">
          {date}
        </div>
      </div>
    </div>
  );
};

const BeginHere = () => {
  const navigateToBoard = (id) => {
    navigate(`${routes.savedboard}/${id}`);
  };

  const [savedRooms, setSavedRooms] = useState([
    // { title: "Test 1", date: "1/1/1", id: 1},
    // { title: "Test 2", date: "2/2/2" , id: 2},
    // { title: "Test 3", date: "3/3/3" , id: 3},
    // { title: "Test 4", date: "4/4/4" , id: 4},
    // { title: "Test 5", date: "5/5/5" , id: 5},
    // { title: "Test 6", date: "6/6/6" , id: 6},
    // { title: "Test 7", date: "7/7/7" , id: 7},
    // { title: "Test 8", date: "12/12/12" , id: 8},
    // { title: "Test 9", date: "TEMP" , id: 9},
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [currentPositionDesktop, setCurrentPositionDesktop] = useState(0);
  const [currentScrollPercentageDesktop, setCurrentScrollPercentageDesktop] =
    useState(0);
  const [currentPositionMobile, setCurrentPositionMobile] = useState(0);
  const [currentScrollPercentageMobile, setCurrentScrollPercentageMobile] =
    useState(0);

  const scrollContainerRefDesktop = useRef(null);
  const scrollContainerRefMobile = useRef(null);
  const scrollTimeoutRefDesktop = useRef(null);
  const scrollTimeoutRefMobile = useRef(null);

  // const checkAuthStatus = async () => {
  //   const authenticated = await dispatch(checkUserAuth(user, navigate));
  //   if (!authenticated) {
  //     navigate(routes.login);
  //   }
  // };

  const updateSavedRooms = async () => {
    const unsortedData = await dispatch(getAllSavedRooms(user, navigate));
    console.log("Unsorted data: ", unsortedData);
    if (unsortedData && Array.isArray(unsortedData)) {
      const data = unsortedData.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      console.log("Sorted data: ", data);
      const formattedData = data.map((room) => {
        const jsDate = new Date(room.date);
        const formattedDate = `${
          jsDate.getMonth() + 1
        }/${jsDate.getDate()}/${jsDate.getFullYear()}`;
        return {
          title: room.sessionName,
          date: formattedDate,
          id: room.id, // This field is stored but not rendered
        };
      });
      setSavedRooms(formattedData);
    } else {
      console.log("No saved rooms data available");
      setSavedRooms([]); // Set to empty array if no data
    }
  };

  useEffect(() => {
    if (!user) {
      console.log(!user);
      navigate(routes.login);
    } else {
      if (user) {
        dispatch(verificationAndAuth(navigate));
      }
      updateSavedRooms();
    }
  }, []);

  const createSessionClicked = () => {
    navigate(routes.createSession);
  };

  const joinSessionClicked = () => {
    navigate(routes.joinSession);
  };

  // Scroll effect for desktop
  useEffect(() => {
    const scrollContainer = scrollContainerRefDesktop.current;

    const handleScroll = () => {
      const maxScrollLeft =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;
      const scrollPercentage =
        (scrollContainer.scrollLeft / maxScrollLeft) * 100;
      let flooredScrollPercentage = Math.floor(scrollPercentage);
      flooredScrollPercentage =
        flooredScrollPercentage == 99 ? 100 : flooredScrollPercentage;
      setCurrentScrollPercentageDesktop(flooredScrollPercentage);
      setCurrentPositionDesktop(scrollContainer.scrollLeft);
    };

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll effect for mobile
  useEffect(() => {
    const scrollContainer = scrollContainerRefMobile.current;

    const handleScroll = () => {
      const maxScrollLeft =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;
      const scrollPercentage =
        (scrollContainer.scrollLeft / maxScrollLeft) * 100;
      let flooredScrollPercentage = Math.floor(scrollPercentage);
      flooredScrollPercentage =
        flooredScrollPercentage == 99 ? 100 : flooredScrollPercentage;
      setCurrentScrollPercentageMobile(flooredScrollPercentage);
      setCurrentPositionMobile(scrollContainer.scrollLeft);
    };

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNextDesktop = () => {
    const scrollContainer = scrollContainerRefDesktop.current;
    const itemWidth = scrollContainer.scrollWidth / savedRooms.length;
    let snapPosition = currentPositionDesktop + itemWidth;

    snapPosition = Math.min(
      Math.round(snapPosition / itemWidth) * itemWidth,
      scrollContainer.scrollWidth - scrollContainer.clientWidth
    );

    scrollContainer.scrollTo({
      left: snapPosition,
      behavior: "smooth",
    });
    setCurrentPositionDesktop(snapPosition);
  };

  const handlePrevDesktop = () => {
    const scrollContainer = scrollContainerRefDesktop.current;
    const itemWidth = scrollContainer.scrollWidth / savedRooms.length;
    let snapPosition = currentPositionDesktop - itemWidth;

    snapPosition = Math.max(
      Math.round(snapPosition / itemWidth) * itemWidth,
      0
    );

    scrollContainer.scrollTo({
      left: snapPosition,
      behavior: "smooth",
    });
    setCurrentPositionDesktop(snapPosition);
  };

  const thumbPercentage = 20;
  const thumbPositionDesktop =
    (currentScrollPercentageDesktop * (100 - thumbPercentage)) / 100;
  const thumbPositionMobile =
    (currentScrollPercentageMobile * (100 - thumbPercentage - 5)) / 100;

  return (
    <div className="min-h-screen w-full font-poppins relative text-center overflow-x-hidden">
      <img
        src="https://brainwrite-assets.s3.amazonaws.com/peaks-bg.svg"
        alt="Background waves"
        className="absolute inset-0 w-full h-full object-cover"
        loading="lazy"
      />
      <div className="flex flex-col w-full min-h-screen relative z-10 mt-0 md:mt-10">
        {/* Logo and Header */}
        <div className="flex flex-col justify-center items-center mt-0 md:mt-10">
          <img
            src="https://brainwrite-assets.s3.amazonaws.com/logo-orange.svg"
            alt="Brainwrite Logo"
            className="w-[80px] sm:w-[70px] mt-[14vh] md:mt-[14vh] mb-[32px] md:mb-[4vh]"
          />
        </div>

        {/* Session Buttons */}
        <div className="flex flex-col md:flex-row justify-center items-center gap-6 w-[196px] md:w-full mx-auto mb-8 md:mb-0 mt-0 md:mt-5">
          <div
            className="text-base font-semibold rounded-full cursor-pointer text-orange500 bg-white py-4 w-full md:w-[196px] hover:ring-[1.6px] hover:ring-orange500 active:ring-[2.4px]"
            onClick={createSessionClicked}
          >
            Create Session
          </div>
          <div
            className="text-base font-semibold rounded-full cursor-pointer bg-white py-4 w-full md:w-[196px] hover:ring-[1.6px] hover:ring-orange900 active:ring-[2.4px]"
            onClick={joinSessionClicked}
          >
            Join Session
          </div>
        </div>

        {/* Mobile Scroll box */}
        <div className="flex flex-col mx-0 mb-5 md:hidden">
          <div className="font-semibold text-base md:text-2xl text-left mt-[5vh] md:mt-[7vh] mx-[6vw] md:mx-0 text-orange900">
            Saved session boards
          </div>
          <div
            className="overflow-x-auto pb-[4vh] pt-[2vh] md:pt-[2vh] hide-scrollbar"
            ref={scrollContainerRefMobile}
          >
            <div className="flex space-x-4 px-4 hide-scrollbar">
              {savedRooms.map((item, index) => {
                return (
                  <GridItem
                    key={index}
                    title={item.title}
                    date={item.date}
                    id={item.id}
                    onClick={navigateToBoard}
                  />
                );
              })}
            </div>
          </div>
          <div className="w-[320px] mx-auto">
            <div className="scrollbar-container rounded-3xl bg-white h-1">
              <div
                className={`scrollbar-thumb w-[80px] h-full bg-orange900 rounded-3xl`}
                style={{ left: `${thumbPositionMobile}%` }}
              ></div>
            </div>
          </div>
        </div>

        {/* Desktop Scroll box */}
        <div className="hidden md:block w-full px-10 mt-10">
          <div className="max-w-[1200px] mx-auto p-10 rounded-lg">
            <div className="w-full h-full">
              <div className="font-semibold text-base ml-12 text-left">
                Saved session boards
              </div>
              <div className="flex flex-row w-full align-middle items-center justify-start slide-container">
                <div>
                  <img
                    src="https://brainwrite-assets.s3.amazonaws.com/begin-here-arrow.svg"
                    alt="Previous"
                    className="w-8 cursor-pointer hover:opacity-70"
                    onClick={handlePrevDesktop}
                  />
                </div>
                <div
                  className="flex flex-col overflow-x-auto pb-[2vh] pt-[2vh] mx-[20px] scrollable-container w-full"
                  ref={scrollContainerRefDesktop}
                >
                  <div className="flex items-center content-center gap-[20px] justify-start slide-track">
                    {savedRooms.map((item, index) => (
                      <GridItem
                        key={index}
                        title={item.title}
                        date={item.date}
                        id={item.id}
                        onClick={navigateToBoard}
                      />
                    ))}
                  </div>
                </div>
                <div>
                  <img
                    src="https://brainwrite-assets.s3.amazonaws.com/begin-here-arrow.svg"
                    alt="Next"
                    className="w-8 cursor-pointer rotate-180 hover:opacity-70"
                    onClick={handleNextDesktop}
                  />
                </div>
              </div>
              <div className="w-[400px] mx-auto mt-[4vh] mb-[9vh]">
                <div className="scrollbar-container rounded-3xl bg-white h-1">
                  <div
                    className={`scrollbar-thumb w-[80px] h-full bg-orange900 rounded-3xl`}
                    style={{ left: `${thumbPositionDesktop}%` }}
                  ></div>
                </div>
              </div>
            </div>
            {/* <div className="text-black w-full">
              <h1>You've stumbled onto this site early!</h1>
              <h1>
                {
                  "We are still building and testing, check back September 22nd :)"
                }
              </h1>
            </div> */}
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-auto w-full">
          <BottomBar />
        </div>
      </div>
    </div>
  );
};

export default BeginHere;
