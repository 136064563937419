/** @format */

import React, { useState, useEffect } from "react";
import BottomBar from "../components/BottomBar.jsx";
import { joinRoom } from "../actions/rooms.js";
import { checkUserAuth, verificationAndAuth } from "../actions/auth.js";

import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as routes from "../constants/routeNames.js";

const JoinSession = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [roomCode, setRoomCode] = useState(""); // State to manage room code input
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const checkAuthStatus = async () => {
    console.log("Checking user auth");
    const authenticated = await dispatch(checkUserAuth(user, navigate));
    if (!authenticated) {
      navigate(routes.login);
    }
  };
  useEffect(() => {
    const urlRoomCode = params.get("roomcode");
    if (urlRoomCode) {
      setRoomCode(urlRoomCode); // Set the room code from URL
      if (user && urlRoomCode.length === 4) {
        dispatch(joinRoom(user, urlRoomCode, navigate)); // Automatically join the room if code is valid
      }
    }
  }, [location.search, user]);

  useEffect(() => {
    if (!user) {
      console.log(!user);
      navigate(routes.login);
    } else {
      if (user) {
        dispatch(verificationAndAuth(navigate));
      }
    }
  }, []);

  const backArrowPressed = () => {
    navigate(routes.beginHere);
  };

  const joinClicked = () => {
    if (user) {
      if (roomCode.length == 4) {
        dispatch(joinRoom(user, roomCode, navigate));
      } else {
        if (roomCode == 0) {
          console.log("Please enter 4 character session ID (Case Sensitive).");
        } else {
          console.log("Session ID must be 4 characters (Case Sensitive).");
        }
      }
    }
  };

  return (
    !params.get("roomcode") && (
      <div className="bg-profile">
        <div className="flex flex-col">
          <div className="flex mt-28 mb-[-7rem] ml-5 md:ml-10 h-[20px] mb-0 hover:cursor-pointer">
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/back-arrow-createsession.svg"
              alt="back"
              onClick={backArrowPressed}
            />
          </div>
        </div>

        <div className="h-[100vh] w-[100vw] flex justify-center items-center ">
          <div className="flex flex-col items-center">
            <img
              className="w-[80px] h-auto m-10"
              src="https://brainwrite-assets.s3.amazonaws.com/logo-orange.svg"
              alt="Brainwrite B Logo"
            />
            <div className="mb-4">
              <div className="flex flex-col items-center">
                <input
                  className="appearance-none border-none rounded-lg w-[90vw] max-w-[400px] h-[50px] py-2 px-3 text-orange900 leading-tight font-medium placeholder-graybrown400 focus:outline-none focus:ring-2 focus:ring-orange500"
                  id="roomCode"
                  type="text"
                  placeholder="Enter Session ID"
                  value={roomCode}
                  onChange={(e) => setRoomCode(e.target.value)}
                />
                <button
                  className="items-center m-4 justify-center bg-orange500 hover:bg-orange400 text-white font-medium rounded-full w-[90vw] max-w-[400px] h-[50px]"
                  onClick={joinClicked}
                >
                  Join
                </button>
              </div>
            </div>
          </div>
        </div>
        <BottomBar />
      </div>
    )
  );
};

export default JoinSession;
