/** @format */

import React, { useState, useRef, useEffect, useCallback } from "react";
import "flowbite";
import BottomBar from "../components/BottomBar.jsx";
import * as routes from "../constants/routeNames.js";
import { LOGOUT } from "../constants/actionTypes.js";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "../actions/user.js";
import { checkUserAuth, verificationAndAuth } from "../actions/auth.js";

const Profile = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(
    user?.profilePhoto ||
      "https://brainwrite-assets.s3.amazonaws.com/upload-img.svg"
  );
  const [showPassword, setShowPassword] = useState(false);
  const [hasCustomImage, setHasCustomImage] = useState(false);
  const [fullName, setFullName] = useState(""); // State for full name
  const [companyName, setCompanyName] = useState(""); // State for company name
  const [email, setEmail] = useState(""); // State for email
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  const checkAuthStatus = async () => {
    console.log("Checking user auth");
    const authenticated = await dispatch(checkUserAuth(user, navigate));
    if (!authenticated) {
      navigate(routes.login);
    }
  };

  useEffect(() => {
    if (!user) {
      console.log(!user);
      navigate(routes.login);
    } else {
      if (user) {
        dispatch(verificationAndAuth(navigate));
      }
      setFullName(user.result.fullName);
      setCompanyName(user.result.companyName);
      setEmail(user.result.email); // Initialize email from user profile
      setPreviewUrl(
        user.result.profilePhoto ||
          "https://brainwrite-assets.s3.amazonaws.com/upload-img.svg"
      );
    }
  }, [user, navigate]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("File ", file);
    if (file) {
      setSelectedFile(file);
      setHasCustomImage(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      console.log("Selected file:", file.name);
    }
  };

  const signOutClicked = useCallback(() => {
    dispatch({ type: LOGOUT });
    setUser(null);
    localStorage.removeItem("profile");
    navigate(routes.login);
  }, [dispatch, navigate]);

  const handleRemove = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove your profile photo?"
    );
    if (isConfirmed) {
      setSelectedFile(null);
      setHasCustomImage(false);
      setPreviewUrl(
        "https://brainwrite-assets.s3.amazonaws.com/upload-img.svg"
      );
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleProfileSave = async () => {
    setSuccessMessage(""); // Reset success message
    setErrorMessage(""); // Reset error message

    const formData = new FormData();
    if (selectedFile) {
      formData.append("profileImage", selectedFile);
    }
    formData.append("fullName", fullName);
    formData.append("companyName", companyName);
    //formData.append("email", email);

    const response = await dispatch(updateProfile(user, formData, navigate));

    if (response.success) {
      setSuccessMessage("Profile saved successfully!");
    } else {
      setErrorMessage(response.error || "Failed to save profile.");
    }
  };

  return (
    <div>
      <div className="w-full min-h-screen flex items-center justify-center bg-profile">
        <div className="bg-white rounded-lg w-full md:w-auto mx-5 px-8 pt-6 pb-12 mt-32 mb-20 relative">
          <h1 className="text-2xl mb-1">Profile</h1>
          <div>
            <div>
              <hr className="w-[full] -mx-5 h-[1px] -mb-[1px] bg-profile border-0"></hr>
              <hr className="w-[73px] h-[1px] bg-orange500 border-0"></hr>
            </div>
            <div className="flex flex-col md:flex-row gap-6 md:items-between md:justify-start">
              <div>
                {/* Full name */}
                <div className="mt-10 md:mt-5 w-[75%] md:w-[224px]">
                  <label
                    className="block text-orange900 text-xs font-normal mb-1"
                    htmlFor="full-name"
                  >
                    Full name
                  </label>
                  <input
                    className="appearance-none border border-graybrown400 rounded w-full py-2 px-3 text-orange900 text-xs placeholder-graybrown400 leading-tight focus:outline-none mt-1 mb-5"
                    id="full-name"
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder=""
                  />
                </div>

                {/* Company name */}
                <div className="w-[75%] md:w-full">
                  <label
                    className="block text-orange900 text-xs font-normal mb-2"
                    htmlFor="company-name"
                  >
                    Company name
                  </label>
                  <input
                    className="appearance-none border border-graybrown400 rounded w-full py-2 px-3 text-orange900 text-xs placeholder-graybrown400 leading-tight focus:outline-none"
                    id="company-name"
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder=""
                  />
                </div>
              </div>

              <div className="mt-0 md:mt-5 md:w-[224px]">
                {/* Email address */}
                <div className="w-[75%] md:w-full">
                  <label
                    className="block text-orange900 text-xs font-normal mb-2"
                    htmlFor="email-address"
                  >
                    Email address
                  </label>
                  <input
                    className="appearance-none border bg-graybrown300 border-graybrown400 rounded w-full py-2 px-3 text-orange900 text-xs placeholder-graybrown400 leading-tight focus:outline-none mb-5 cursor-no-drop"
                    id="email-address"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder=""
                    disabled="true"
                  />
                </div>

                {/* Current password */}
                <div className="w-[75%] md:w-full relative">
                  <label
                    htmlFor="password"
                    className="block text-orange900 text-xs font-normal mb-2"
                  >
                    Password
                  </label>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <img
                      src="https://brainwrite-assets.s3.amazonaws.com/pass-icon.svg"
                      alt="Password icon"
                      className="h-[10px] mt-1"
                    />
                  </div>
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="••••••••••••"
                    className="appearance-none border border-graybrown400 rounded w-full py-2 px-6 text-orange900 text-xs placeholder-graybrown400 leading-tight focus:outline-none mb-5 cursor-no-drop"
                    disabled="true"
                  />
                  <div
                    className="absolute mt-1 -inset-y-16 right-0 pr-1 flex items-center cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    <img
                      src={
                        showPassword
                          ? "https://brainwrite-assets.s3.amazonaws.com/eye-icon.svg"
                          : "https://brainwrite-assets.s3.amazonaws.com/show-pass.svg"
                      }
                      alt={showPassword ? "Hide password" : "Show password"}
                      className="hover:brightness-95 w-6 h-6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className="w-[full] -mx-5 h-[1px] -mb-[1px] bg-profile border-0"></hr>

            <div className="flex flex-col md:flex-row md:items-end">
              {/* Your photo */}
              <div className="flex flex-col gap-2 mb-8 mt-6">
                <h2 className="text-orange900 text-base">Your Photo</h2>
                <div className="flex flex-row gap-4 relative">
                  <img
                    src={previewUrl}
                    className="w-[72px] h-[72px] object-cover hover:brightness-95 hover:cursor-pointer rounded-xl"
                    onClick={handleUploadClick}
                    alt="Upload"
                  />
                  {/* {hasCustomImage && (
                    <div className="absolute w-[72px] h-[72px] inset-0 flex items-center justify-start opacity-0 hover:opacity-30 transition-opacity duration-300">
                      <img
                        src="https://brainwrite-assets.s3.amazonaws.com/remove-icon.svg"
                        alt="Remove"
                        className="w-[15px] cursor-pointer"
                        onClick={handleRemove}
                      />
                    </div>
                  )} */}
                </div>
                <input
                  type="file"
                  id="file-input"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </div>
              <div className="flex flex-row md:flex-col justify-between items-end md:ml-20">
                <div className="flex flex-col items-start gap-4 mb-0 md:mb-1">
                  <button
                    className="rounded hover:brightness-95 text-orange500 hover:text-orange400 font-semibold"
                    onClick={signOutClicked}
                  >
                    Sign Out
                  </button>
                  <button className="rounded whitespace-nowrap hover:text-graybrown500 mb-8">
                    Delete My Profile
                  </button>
                </div>
                <button
                  className="bg-orange500 hover:bg-orange400 w-auto px-6 py-2 text-white rounded-full absolute left-8 md:left-auto md:right-8 bottom-6 text-sm font-semibold mt-0"
                  onClick={handleProfileSave}
                >
                  Save
                </button>
              </div>
            </div>
            {/* Conditionally render success or error messages */}
            {successMessage && (
              <div className="text-green-500 text-xs mt-4">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="text-red-500 text-xs mt-4">{errorMessage}</div>
            )}
          </div>
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default Profile;
