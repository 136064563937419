/** @format */

import React, { useState, useEffect } from "react";
import BottomBar from "../components/BottomBar.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import { checkUserAuth, verificationAndAuth } from "../actions/auth.js";
import { makeRoom, startRoom } from "../actions/rooms.js";
import { useDispatch } from "react-redux";
//import io from "socket.io-client";
import { getParticipants } from "../actions/rooms.js";
import socket from "../socket.js"; // Import the shared socket instance
import * as routes from "../constants/routeNames.js";
import ParticipantsBox from "../components/ParticipantsBox.jsx";
import "flowbite";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import _ from "lodash";
const serverUrl = `${process.env.REACT_APP_SERVER_BASE_URL}`;

// const socket = io(serverUrl);

const CreateSession = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const defaultSettings = {
    selectedVotingMethod: "Dot voting",
    numberOfRounds: 7,
    ideasPerRound: 3,
    timePerRound: 1, //change back to 5
    anonymity: false,
  };
  const quickBPresetSettings = {
    selectedVotingMethod: "Dot voting",
    numberOfRounds: 3,
    ideasPerRound: 3,
    timePerRound: 2,
    anonymity: true,
  };
  const deepBPresetSettings = {
    selectedVotingMethod: "Random Sampling",
    numberOfRounds: 5,
    ideasPerRound: 6,
    timePerRound: 5,
    anonymity: true,
  };

  const [roomCode, setRoomCode] = useState(
    JSON.parse(localStorage.getItem("room"))?.roomCode
  );
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [loading, setLoading] = useState(true); // Add loading state
  const [quickBIsOpen, setQuickBIsOpen] = useState(false);
  const [deepBIsOpen, setDeepBIsOpen] = useState(false);
  const [presetSelection, setPresetSelection] = useState(0);
  const [teamQuestion, setTeamQuestion] = useState(""); // Add state for team question

  const OnLoadData = location.state?.OnLoadData;
  const [roomSettings, setRoomSettings] = useState(
    OnLoadData ? OnLoadData : defaultSettings
  );
  const [users, setUsers] = useState([]);

  useEffect(() => {
    socket.emit("joinRoom", { roomCode });

    socket.on("userJoined", (email) => {
      console.log("USER JOINED");
      updateUsers();
    });

    return () => {
      socket.off("userJoined");
      socket.emit("leaveRoom", { roomCode });
    };
  }, [roomCode]);

  const updateUsers = async () => {
    if (user && roomCode) {
      const participants = await dispatch(getParticipants(user, roomCode));
      console.log("Participants: ", participants);
      setUsers(participants);
    }
  };
  const checkAuthStatus = async () => {
    console.log("Checking user auth");
    const authenticated = await dispatch(checkUserAuth(user, navigate));
    if (!authenticated) {
      navigate(routes.login);
    }
  };

  useEffect(() => {
    if (!user) {
      console.log(!user);
      navigate(routes.login);
    } else {
      if (user) {
        dispatch(verificationAndAuth(navigate));
      }
    }
  }, []);

  useEffect(() => {
    updateUsers();
  }, []);

  useEffect(() => {
    if (_.isEqual(roomSettings, quickBPresetSettings)) {
      setPresetSelection(1);
    }
    if (_.isEqual(roomSettings, deepBPresetSettings)) {
      setPresetSelection(2);
    }
  }, [roomSettings]);

  const changeQuickBState = () => {
    setQuickBIsOpen(!quickBIsOpen);
  };
  const changeDeepBState = () => {
    setDeepBIsOpen(!deepBIsOpen);
  };
  const startPressed = () => {
    console.log("Start pressed");
    dispatch(startRoom(user, roomSettings, teamQuestion, roomCode, navigate));
  };

  const backArrowPressed = () => {
    navigate(routes.beginHere);
  };
  const customizePressed = () => {
    navigate(routes.sessionSettings, { state: { OnLoadData: roomSettings } });
  };

  //IF YOU EVER GET AUTOMATICALLY REDIRECTED TO LOGIN (On this page or any page)
  //and you are builiding / testing,
  //Find the lines marked *** and comment them out
  //(These lines will be in multiple files)

  useEffect(() => {
    if (user) {
      setLoading(false); //If you comment out the above line, uncomment this
    }
  }, []);

  useEffect(
    () => {
      if (!user) {
        //navigate("/login"); //***
        setLoading(false); //If you comment out the above line, uncomment this
        // } else if (!roomCode) {
      } else {
        dispatch(makeRoom(user, navigate)).then(() => {
          try {
            const room = JSON.parse(localStorage.getItem("room"));
            if (room) {
              setRoomCode(room.roomCode);
            }
          } catch (error) {
            console.error(error);
            setRoomCode("");
          } finally {
            setLoading(false);
          }
        });
      }
    },
    //   else {
    //     setLoading(false);
    //   }
    // }
    []
  );

  const handlePresetSelection = (preset) => {
    setPresetSelection(preset);
    if (preset === 1) {
      setRoomSettings(quickBPresetSettings);
    }
    if (preset === 2) {
      setRoomSettings(deepBPresetSettings);
    }
  };

  const [settings, setSettings] = useState(
    OnLoadData
      ? OnLoadData
      : {
          selectedVotingMethod: "Dot voting",
          numberOfRounds: 7,
          ideasPerRound: 3,
          timePerRound: 5,
          anonymity: false,
        }
  );

  const handleChange = (name, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const toggleAnonymity = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      anonymity: !prevSettings.anonymity,
    }));
  };

  const sliderStyle = {
    trackStyle: [{ backgroundColor: "#E0752D", height: 8 }],
    railStyle: { backgroundColor: "#F7D1B7", opacity: 10, height: 8 },
    handleStyle: [
      {
        backgroundColor: "white",
        opacity: 100,
        borderColor: "#E0752D",
        height: 16,
        width: 16,
        top: 6,
      },
    ],
  };

  // invite link
  const [inviteLink, setInviteLink] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (roomCode) {
      setInviteLink(`${window.location.origin}/join?roomcode=${roomCode}`);
    }
  }, [roomCode]);

  const copyInviteLink = () => {
    navigator.clipboard
      .writeText(inviteLink)
      .then(() => {
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 1500); // Hide tooltip after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  if (loading) {
    return <div>Loading...</div>; // Render a loading state
  }

  return (
    <div className="min-h-screen flex flex-col items-center bg-profile overflow-x-hidden">
      <div className="min-h-screen w-full flex flex-col justify-around">
        <div className="flex justify-between w-full">
          <div className="mt-28 ml-5 md:ml-10 h-[20px] hover:cursor-pointer">
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/back-arrow-createsession.svg"
              alt="back"
              onClick={backArrowPressed}
            />
          </div>

          {/* currently hidding the settings icon */}
          <div className="hidden mr-5 md:mr-10 mt-28 hover:cursor-pointer">
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/settings-icon-gray-createsession.svg"
              alt="settings"
              onClick={customizePressed}
              className="transition duration-300 ease-in-out hover:rotate-90 rounded-full w-[20px]"
            />
          </div>
        </div>
        <div className="flex-col justify-center items-center mt-2 md:mt-0">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-graybrown400 mb-2 text-center select-none">
            Session ID:
          </p>
          <h1 className="font-bold text-5xl md:text-6xl text-orange900 text-center">
            {roomCode}
          </h1>
          <img
            className="mx-auto mt-1"
            src="https://brainwrite-assets.s3.amazonaws.com/orangeline2.svg"
            alt="line"
          />
        </div>

        {/* team question small/mobile */}
        <div className="flex mx-5 md:hidden items-center justify-center mb-5 mt-10">
          <input
            className="text-base w-[335px] h-[48px] pl-3 text-left mx-auto placeholder-graybrown400  text-orange900 border-none rounded-lg focus:outline-none focus:ring-2 focus:ring-orange500"
            id="team-question"
            type="text"
            placeholder="Enter your question for the team..."
            value={teamQuestion} // Controlled input
            onChange={(e) => setTeamQuestion(e.target.value)} // Update state on change
          ></input>
        </div>

        {/* team question medium */}
        <div>
          <div className="hidden md:flex justify-center items-center mt-0">
            <div>
              <input
                className="border-2 border-none placeholder-graybrown400 text-orange900 text-base py-3 w-[60vw] pl-3 rounded-lg mr-6 text-left focus:outline-none focus:ring-2 focus:ring-orange500"
                id="team-question"
                type="text"
                placeholder="Enter your question for the team..."
                value={teamQuestion} // Controlled input
                onChange={(e) => setTeamQuestion(e.target.value)} // Update state on change
              ></input>
            </div>

            {/* start button medium */}
            <div className="group">
              <button
                className="bg-orange500 rounded-full text-orange800 font-medium px-5 py-3 hover:cursor-pointer"
                onClick={startPressed}
              >
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/white-arrow.svg"
                  alt="enter"
                  className="w-[23px]  transition duration-300 group-hover:translate-x-2"
                />
              </button>
            </div>
          </div>
        </div>

        {/* settings box */}
        <div className="flex flex-col md:flex-row items-stretch justify-center mx-5 gap-4 select-none">
          <div className="w-full h-full flex flex-col gap-4 rounded-2xl max-w-[600px] bg-white p-6">
            <div className="flex justify-start bg-profile w-full rounded-lg px-3 py-3 relative">
              <div className="flex gap-2">
                <img
                  src="https://brainwrite-assets.s3.amazonaws.com/session-link.svg"
                  alt="session invite link"
                  className="w-6 h-6 cursor-pointer"
                />
                <a
                  onClick={copyInviteLink}
                  className="text-base font-normal cursor-pointer"
                >
                  {inviteLink}
                </a>
              </div>
              <div className="absolute right-1 top-1">
                <button
                  onClick={copyInviteLink}
                  className="bg-white w-[72px] h-10 rounded-lg text-base font-orange900 hover:drop-shadow-custom relative"
                >
                  Copy
                  <div
                    className={`absolute -top-8 left-1/2 transform -translate-x-1/2 bg-orange500 text-white text-xs rounded py-1 px-2 whitespace-nowrap transition-opacity duration-500 ${
                      showTooltip ? "opacity-100" : "opacity-0"
                    }`}
                  >
                    Copied!
                  </div>
                </button>
              </div>
            </div>
            <div className="flex justify-between items-center border-[1px] border-graybrown400 w-full rounded-lg p-3">
              <h2>Anonymity</h2>
              <label className="flex justify-between items-center">
                <input
                  type="checkbox"
                  className="mr-5 appearance-none peer invisible"
                  checked={settings.anonymity}
                  onChange={toggleAnonymity}
                ></input>
                <span className="hover:cursor-pointer w-12 h-7 flex items-center flex-shrink-0  bg-graybrown200 rounded-full p-1 after:w-5 after:h-5 after:bg-white after:rounded-full after:shadow-md peer-checked:bg-orange500 duration-300 ease-in-out after:duration-300 peer-checked:after:translate-x-5"></span>
              </label>
            </div>

            <div>
              <label
                htmlFor="timePerRound"
                className="flex font-medium ml-1 text-base mb-2 select-none -mt-1"
              >
                Time per Round
              </label>
              <Slider
                id="timePerRound"
                min={1}
                max={20}
                value={settings.timePerRound}
                onChange={(value) => handleChange("timePerRound", value)}
                {...sliderStyle}
              />
            </div>
            <span className="flex font-medium text-graybrown400 ml-1 text-base -mt-1 select-none">
              {settings.timePerRound} Minutes
            </span>
          </div>
          {/* start button small/mobile */}
          <div className="flex md:hidden justify-end mx-5">
            <button
              className="group bg-orange500 rounded-full text-orange800 font-medium px-5 py-3 max-w-[80px] hover:cursor-pointer"
              onClick={startPressed}
            >
              <img
                src="https://brainwrite-assets.s3.amazonaws.com/white-arrow.svg"
                alt="enter"
                className="w-[23px] transition duration-300 group-hover:translate-x-2"
              />
            </button>
          </div>
          <div className="h-[260px] pb-5 mb-5 md:pb-0 md:mb-0">
            <ParticipantsBox participants={users} option={2} />
          </div>
        </div>
      </div>

      <div className="mt-auto w-full bottom-0">
        <BottomBar />
      </div>
    </div>
  );
};

export default CreateSession;
