/** @format */

export const landingpage = "/";
export const login = "/signin";
export const signup = "/signup";
export const joinSession = "/join";
export const createSession = "/create";
export const session = "/session";
export const votingRandomSampling = "/session/vote";
export const buildOn = "/buildOn";
export const chrome = "/chrome";
export const leaderboard = "/session/leaderboard";
export const savedboard = "/board";
export const profile = "/profile";
export const beginHere = "/dashboard";
export const sessionSettings = "/session/settings";
export const waitingToStart = "/waitingToStart";
export const feedbackForm = "/feedbackForm";
export const verifyEmail = "/signup/verify";
export const privacyPolicy = "/privacy-policy";

/*
routes.landingpage
routes.profile
routes.beginHere
routes.login
routes.signup
routes.landingpage
routes.createSession
routes.sessionSettings
routes.waitingToStart
routes.session
routes.votingRandomSampling
routes.leaderboard
routes.savedboard


          */
