/** @format */

import { AUTH, LOGOUT } from "../constants/actionTypes";
import * as api from "../api/index.js";
import * as routes from "../constants/routeNames.js";

export const signin = (formData, navigate) => async (dispatch) => {
  console.log("Signing in");
  console.log(formData);

  try {
    console.log("Signing in 2");
    const response = await api.signIn(formData);
    const data = response?.data;
    console.log("Signing in 3");
    dispatch({ type: AUTH, data });
    console.log("Signing in 4");
    if (!data?.isVerified) {
      const id = data._id;
      try {
        // const response = await api.sendVerificationEmail(id);
        navigate(routes.verifyEmail);
      } catch (error) {
        console.log(error);
        navigate(routes.login);
      }
    } else {
      navigate(routes.beginHere);
    }
  } catch (error) {
    console.log("Error in signin:", error);
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};
const redirectLogin = async (navigate) => {
  console.log("redirecting login");

  const userInfo = JSON.parse(localStorage.getItem("profile"));
  const id = userInfo?.result?._id;
  console.log("userInfo: ", userInfo);

  const verifResponse = await api.checkVerification(id);
  const verifData = verifResponse?.data;
  console.log("Verification status: ", verifData);
  if (!verifData?.verified) {
    try {
      // const response = await api.sendVerificationEmail(id);
      navigate(routes.verifyEmail);
    } catch (error) {
      console.log(error);
      navigate(routes.login);
    }
  } else {
    navigate(routes.beginHere);
  }
};

export const signup = (formData, navigate) => async (dispatch) => {
  try {
    const response = await api.signUp(formData);
    const data = response?.data;
    dispatch({ type: AUTH, data });
    const id = data._id;
    try {
      const response = await api.sendVerificationEmail(id);
      navigate(routes.verifyEmail);
    } catch (error) {
      console.log(error);
      navigate(routes.login);
    }
  } catch (error) {
    console.log("Error in signup:", error);
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};
export const googleAuth =
  (tokenResponse, profile, navigate) => async (dispatch) => {
    // console.log("In googleAuth action. Token response:");
    // console.log(tokenResponse);
    // console.log("PROFILE: ");
    // console.log(profile);
    try {
      const response = await api.googleAuth({
        tokenInput: tokenResponse,
        profile,
      });
      const data = response?.data;
      dispatch({ type: AUTH, data });
      navigate(routes.beginHere);
    } catch (error) {
      // console.log("Error in Google Auth:", error);
    }
  };

export const checkUserAuth =
  (userInfo, navigate, skipNavigate = false) =>
  async (dispatch) => {
    // console.log("Checking user auth");
    try {
      const id = userInfo?.result?._id;

      const response = await api.checkAuth();
      const data = response?.data;
      // console.log("User authenticated. ");
      // console.log(data);
      return true;
    } catch (error) {
      console.log("User no longer authenticated:", error);
      dispatch({ type: LOGOUT });
      if (!skipNavigate) {
        navigate(routes.login);
      }
      return false;
    }
  };
export const sendVerification = (userInfo, navigate) => async (dispatch) => {
  // console.log("Checking user auth");
  try {
    const id = userInfo?.result?._id;
    console.log("Sending. ID: ", id);
    const response = await api.sendVerificationEmail(id);
    const data = response?.data;
    // console.log("User authenticated. ");
    // console.log(data);
    return true;
  } catch (error) {
    console.log(error);
    return error;
    // navigate(routes.login);
  }
};
export const verifyToken =
  (userInfo, navigate, { code, token }) =>
  async (dispatch) => {
    try {
      const id = userInfo?.result?._id;

      // Prepare formData based on whether `code` or `token` is provided
      const formData = {};
      if (token) formData.token = token;
      if (code) formData.code = code;

      console.log("Checking verification. formData: ", formData);

      const response = await api.verifyCode(id, formData);
      const data = response?.data;

      console.log("Verification response: ", data);
      navigate(routes.beginHere);

      return data; // Return the actual data from the response
    } catch (error) {
      console.log("Error during verification: ", error);

      return {
        message: error?.response?.data?.message || "Verification failed.",
      };
    }
  };
export const checkVerification =
  (userInfo, navigate, skipNavigate = false) =>
  async (dispatch) => {
    try {
      const id = userInfo?.result?._id;

      const response = await api.checkVerification(id);
      const data = response?.data;
      console.log("Verification status: ", data);
      if (!data?.verified) {
        if (!skipNavigate) {
          dispatch({ type: LOGOUT });

          navigate(routes.login);
        }
      }
      return data; // Return the actual data from the response
    } catch (error) {
      console.log("Error during verification: ", error);
      if (!skipNavigate) {
        dispatch({ type: LOGOUT });

        navigate(routes.login);
      }
    }
  };

export const verificationAndAuth =
  (navigate, skipNavigate = false) =>
  async (dispatch) => {
    console.log("Checking verification and auth");
    const user = JSON.parse(localStorage.getItem("profile"));
    if (!user) {
      if (!skipNavigate) {
        navigate(routes.login);
      }
    }
    const id = user?.result?._id;
    try {
      const response1 = await api.checkAuth();
    } catch (error) {
      console.log("User no longer authenticated:", error);
      if (!skipNavigate) {
        dispatch({ type: LOGOUT });

        navigate(routes.login);
      }
      return false;
    }
    console.log("Step 2");
    try {
      const response2 = await api.checkVerification(id);
      const data = response2?.data;
      console.log("Verif data: ", data);
      if (!data?.verified) {
        dispatch({ type: LOGOUT });

        navigate(routes.login);
      }
      return true;
    } catch (error) {
      console.log("Error during verification: ", error);
      dispatch({ type: LOGOUT });
      navigate(routes.login);
    }
  };
