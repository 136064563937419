/** @format */

import React, { useState, useRef, useEffect, useCallback } from "react";
import "flowbite";
import { initFlowbite } from "flowbite";
import { useNavigate } from "react-router-dom";
//import io from "socket.io-client";
import Timer from "./Timer.jsx";
import { getSession } from "../../actions/sessions.js";
import { useDispatch } from "react-redux";
import VotingIntro from "./VotingIntro.js";
import socket from "../../socket.js"; // Import the shared socket instance
import { checkUserAuth } from "../../actions/auth.js";
import * as routes from "../../constants/routeNames.js";

const serverUrl = `${process.env.REACT_APP_SERVER_BASE_URL}`;

// const socket = io(serverUrl);

const MainFlow = () => {
  const [isActive, setIsActive] = useState(true);
  const [expandedCard, setExpandedCard] = useState(0);
  const [ideas, setIdeas] = useState(["", "", ""]);
  const [submittedIdeas, setSubmittedIdeas] = useState([false, false, false]);
  const [completedIdeas, setCompletedIdeas] = useState([false, false, false]);
  const [displayedIdeas, setDisplayedIdeas] = useState(["", "", ""]);
  const [chainIds, setChainIds] = useState(["", "", ""]);
  const [chainContributions, setChainContributions] = useState([[], [], []]);

  const [editingIndex, setEditingIndex] = useState(null);
  const textareaRefs = [useRef(), useRef(), useRef()];
  const [endTime, setEndTime] = useState(null);
  const [readyForNextRound, setReadyForNextRound] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [dataLoaded, setDataLoaded] = useState(false);
  const [teamQuestion, setTeamQuestion] = useState("");
  const [currentRound, setCurrentRound] = useState(1);
  const [totalRounds, setTotalRounds] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [submissionCount, setSubmissionCount] = useState(0);
  const [lastSubmissionTime, setLastSubmissionTime] = useState(0);
  const [authenticated, setAuthenticated] = useState(false);

  const [linkedIdeas, setLinkedIdeas] = useState([[], [], []]);
  const [textLinkedTo, setTextLinkedTo] = useState(["", "", ""]);

  const [links, setLinks] = useState({});

  const checkAuthStatus = async () => {
    console.log("Checking user auth");
    const authenticated = await dispatch(checkUserAuth(user, navigate));
    setAuthenticated(authenticated);
    if (!authenticated) {
      navigate(routes.login);
    }
  };

  useEffect(() => {
    // Initialize Flowbite components
    initFlowbite();
  }, []);

  useEffect(() => {
    if (!user) {
      console.log(!user);
      navigate(routes.login);
    } else {
      if (user) {
        checkAuthStatus();
      }
    }
  }, []);

  useEffect(() => {
    // console.log("displayedIdeas changed: ");
    // console.log(displayedIdeas);
  }, [displayedIdeas]);
  const getSessionInfo = async () => {
    const data = await dispatch(getSession(user, navigate));
    console.log("Received data from dispatch: ", data);

    if (data?.found) {
      const ids = data.ideaChains.map((chain) => chain._id);

      setChainIds(ids);
      setChainContributions(
        data.ideaChains.map((chain) => chain.contributions)
      );
      setLinks(data.links);

      if (data.userSubmitted) {
        setSubmitted(true);
      }
      setSubmissionCount(data.submissionCount);
    }
    if (data) {
      setEndTime(new Date(data.endTime)); // Convert endTime to a Date object
      setTeamQuestion(data.teamQuestion);
      setCurrentRound(data.currentRound);
      setTotalRounds(data.totalRounds);
      setDataLoaded(true); // Set dataLoaded to true after data is processed
    }
  };

  useEffect(() => {
    if (user) {
      getSessionInfo();
    }
  }, []);
  const [roomCode, setRoomCode] = useState(
    JSON.parse(localStorage.getItem("room"))?.roomCode
  );
  const [userId, setUserId] = useState("");
  // useEffect(() => {
  //   console.log("Updated chainIds:", chainIds);
  // }, [chainIds]);

  // useEffect(() => {
  //   console.log("Updated chainText:", chainText);
  // }, [chainText]);
  useEffect(() => {
    if (roomCode) {
      console.log(`Joining room: ${roomCode}`);
      socket.emit("joinRoom", { roomCode });
    }

    // ... other effect code
  }, [roomCode]);

  useEffect(() => {
    // Fetch initial ideas when component mounts
    if (!user) {
      navigate(routes.login);
    } else if (!roomCode) {
      checkAuthStatus();
      console.log("Navihating to begin here");
      navigate(routes.beginHere);
    }
    setUserId(user?.result._id);
    socket.emit("userDetails", { userId });
    socket.on("userSubmitted", (data) => {
      if (data.socketRoomCode == roomCode) {
        setSubmissionCount(data.submissionCount);
      }
    });
    // socket.on("requestFinalSubmissions", (data) => {
    //   if (expandedCard) {
    //     submitCurrentIdea(expandedCard);
    //   }

    //   console.log("Req final received");
    //   console.log("Data:", data);
    //   console.log("Displayed ideas:", displayedIdeas);

    //   const ideaSubmissions = displayedIdeas.map((ideaText, index) => ({
    //     ideaText,
    //     chainId: chainIds[index] || "",
    //   }));

    //   console.log("Sending: ");
    //   console.log(userId);
    //   console.log(roomCode);
    //   console.log(ideaSubmissions);

    //   socket.emit("submitIdeas", {
    //     userId,
    //     roomCode,
    //     ideaSubmissions,
    //   });
    // });
    // socket.onAny((eventName, ...args) => {
    //   console.log(`Received event: ${eventName}`, args);
    // });

    socket.on("ideasRotated", (data) => {
      if (data.socketRoomCode == roomCode) {
        console.log("Received data from rotation: ", data);
        const ids = data.ideaChains.map((chain) => chain._id);
        setChainIds(ids);
        setChainContributions(
          data.ideaChains.map((chain) => chain.contributions)
        );
        // .map((chain) =>
        //   chain.contributions.map((contribution) => contribution.ideaText)
        // );
        setLinks(data.links);

        setEndTime(data.endTime);
        setCurrentRound(data.round);
        setIdeas(["", "", ""]); // Reset input fields
        setDisplayedIdeas(["", "", ""]);
        setTextLinkedTo(["", "", ""]);
        setSubmittedIdeas([false, false, false]);
        setCompletedIdeas([false, false, false]);
        setExpandedCard(0);
        setSubmitted(false);
        setSubmissionCount(0);
      }
    });

    return () => {
      socket.off("initialIdeas");
      socket.off("ideasRotated");
      socket.off("userSubmitted");
      socket.off("assignedContributions");
    };
  }, [navigate, roomCode, user, userId, displayedIdeas]);

  socket.on("assignedContributions", (data) => {
    if (data.socketRoomCode == roomCode) {
      // console.log(data);
      // const voterIdeas = data?.ideas;
      // localStorage.setItem("voterIdeas", JSON.stringify({ voterIdeas }));

      navigate(routes.votingRandomSampling);
    }
  });

  const handleCardClick = (id, e) => {
    e.stopPropagation();
    console.log(`Card clicked: ${id}`);
    setExpandedCard(expandedCard === id ? null : id);
    setEditingIndex(null);
  };

  const handleIdeaChange = (index, value) => {
    const newIdeas = [...ideas];
    newIdeas[index] = value;
    setIdeas(newIdeas);
  };

  //regular submission
  const handleSubmit = (index) => {
    submitCurrentIdea(index);
  };

  // submission that includes clicking next and done buttons
  const submitCurrentIdea = (index) => {
    const ideaText = ideas[index].trim();
    if (ideaText) {
      const newSubmittedIdeas = [...submittedIdeas];
      newSubmittedIdeas[index] = true;
      setSubmittedIdeas(newSubmittedIdeas);
      const newDisplayedIdeas = [...displayedIdeas];
      newDisplayedIdeas[index] = ideaText;
      setDisplayedIdeas(newDisplayedIdeas);
      // console.log("New: ", displayedIdeas);

      const newCompletedIdeas = [...completedIdeas];
      newCompletedIdeas[index] = true;
      setCompletedIdeas(newCompletedIdeas);

      // Clear the textarea
      const newIdeas = [...ideas];
      newIdeas[index] = "";
      setIdeas(newIdeas);

      setEditingIndex(null);

      if (newSubmittedIdeas.every((idea) => idea)) {
        setReadyForNextRound(true);
      }
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(index);
    }
  };

  // next button
  const handleNext = (currentIndex) => {
    submitCurrentIdea(currentIndex);
    if (currentIndex < 2) {
      const nextIndex = currentIndex + 1;
      setExpandedCard(nextIndex);
      setIsActive(true);
    }
  };

  // back arrow
  const handleBack = (currentIndex) => {
    if (currentIndex > 0) {
      const previousIndex = currentIndex - 1;
      setExpandedCard(previousIndex);
      setIsActive(true);
    }
  };
  const handleTimerEnd = useCallback(() => {
    if (!submitted) {
      const currentTime = Date.now();
      if (currentTime - lastSubmissionTime < 5000) {
        // Less than 5 seconds have passed
        console.log("Please wait 5 seconds before submitting again");
        return;
      }

      console.log(
        "Handling timer end with these displayed ideas:",
        displayedIdeas
      );

      if (expandedCard !== null) {
        submitCurrentIdea(expandedCard);
      }
      console.log("Timer ended, submitting ideas");
      console.log(userId);
      console.log(displayedIdeas);

      const ideaSubmissions = displayedIdeas.map((ideaText, index) => ({
        ideaText,
        chainId: chainIds[index] || "",
      }));

      socket.emit("submitIdeas", {
        userId,
        roomCode,
        ideaSubmissions,
        newLinks: textLinkedTo,
      });
      setSubmitted(true);
      setLastSubmissionTime(currentTime);
    }
  }, [
    expandedCard,
    userId,
    displayedIdeas,
    chainIds,
    roomCode,
    submitCurrentIdea,
    submitted,
    lastSubmissionTime,
  ]);

  const handleDone = () => {
    const currentTime = Date.now();
    if (currentTime - lastSubmissionTime < 5000) {
      // Less than 5 seconds have passed
      console.log("Please wait 5 seconds before submitting again");
      return;
    }

    if (expandedCard) {
      submitCurrentIdea(expandedCard);
    }
    console.log("All ideas submitted");
    console.log(userId);
    console.log(displayedIdeas);

    const ideaSubmissions = displayedIdeas.map((ideaText, index) => ({
      ideaText,
      chainId: chainIds[index] || "",
    }));
    // console.log("Idea submissions: ", ideaSubmissions);
    socket.emit("submitIdeas", {
      userId,
      roomCode,
      ideaSubmissions,
      newLinks: textLinkedTo,
    });
    setSubmitted(true);
    setLastSubmissionTime(currentTime);
  };
  const handleEdit = (index, e) => {
    if (e) e.stopPropagation(); // Prevent the card from closing
    // console.log(`Editing idea ${index}`);

    // Ensure the card stays expanded if not in round 1
    if (currentRound > 1) {
      setExpandedCard(index);
    }

    // Set textarea value to the currently displayed idea
    const newIdeas = [...ideas];
    newIdeas[index] = displayedIdeas[index];
    setIdeas(newIdeas);
    setEditingIndex(index);

    // Mark the idea as incomplete to show the textarea again
    const newCompletedIdeas = [...completedIdeas];
    newCompletedIdeas[index] = false;
    setCompletedIdeas(newCompletedIdeas);

    // Mark the idea as not submitted to allow re-submission
    const newSubmittedIdeas = [...submittedIdeas];
    newSubmittedIdeas[index] = false;
    setSubmittedIdeas(newSubmittedIdeas);

    // console.log("Updated ideas:", newIdeas);
    // console.log("Updated completedIdeas:", newCompletedIdeas);
  };

  // New useEffect hook to handle focusing on the textarea
  useEffect(() => {
    if (editingIndex !== null && textareaRefs[editingIndex].current) {
      textareaRefs[editingIndex].current.focus();
      // Move cursor to the end of the text
      const len = textareaRefs[editingIndex].current.value.length;
      textareaRefs[editingIndex].current.setSelectionRange(len, len);
    }
  }, [editingIndex]);

  const getCardColor = (index) => {
    switch (index) {
      case 0:
        return "green100";
      case 1:
        return "purple100";
      case 2:
        return "blue100";
      default:
        return "[#EBFFF8]";
    }
  };

  const getHeaderColor = (index, isExpanded) => {
    if (isExpanded) {
      switch (index) {
        case 0:
          return "bg-green700";
        case 1:
          return "bg-purple700";
        case 2:
          return "bg-blue700";
        default:
          return "bg-green700";
      }
    } else {
      switch (index) {
        case 0:
          return "bg-green700";
        case 1:
          return "bg-purple700";
        case 2:
          return "bg-blue700";
        default:
          return "bg-green700";
      }
    }
  };

  // enter buttons
  const getButtonImage = (index) => {
    switch (index) {
      case 0:
        return "https://brainwrite-assets.s3.amazonaws.com/enterbuttongreen.svg";
      case 1:
        return "https://brainwrite-assets.s3.amazonaws.com/enterbuttonpink.svg";
      case 2:
        return "https://brainwrite-assets.s3.amazonaws.com/enterbuttonblue.svg";
      default:
        return "https://brainwrite-assets.s3.amazonaws.com/enterbuttongreen.svg";
    }
  };

  // back arrow colors
  const getBackArrowImage = (index) => {
    switch (index) {
      case 0:
        return "https://brainwrite-assets.s3.amazonaws.com/backarrowgreen.svg";
      case 1:
        return "https://brainwrite-assets.s3.amazonaws.com/backarrowpink.svg";
      case 2:
        return "https://brainwrite-assets.s3.amazonaws.com/backarrowblue.png";
      default:
        return "https://brainwrite-assets.s3.amazonaws.com/backarrowgreen.svg";
    }
  };

  const handleDotClick = (index) => {
    setExpandedCard(index);
  };

  const getHoverRingColor = (index) => {
    switch (index) {
      case 0:
        return "hover:ring-bwdgreen";
      case 1:
        return "hover:ring-bwdpink";
      case 2:
        return "hover:ring-newdblue";
      default:
        return "hover:ring-bwdgreen";
    }
  };

  const getSubmittedIdeaColor = (index) => {
    switch (index) {
      case 0:
        return "bg-[#CBE7D5]"; // Pale green
      case 1:
        return "bg-[#F2C3D7]"; // Pale pink
      case 2:
        return "bg-[#C4E0F1]"; // Pale blue
      default:
        return "bg-[#CBE7D5]";
    }
  };

  //submit ideas for next round button
  const handleReadyForNextRound = () => {
    console.log("User ready for next round");
    setIsReady(!isReady);
    socket.emit("readyForNextRound", {
      userId,
      roomCode,
    });
    // You might want to add some UI feedback here, like:
    // setReadyForNextRound(false);
    // setWaitingForOthers(true);
  };

  //disable submit button
  const isDisabled =
    currentRound === 1
      ? !submittedIdeas.every(Boolean)
      : !readyForNextRound || expandedCard !== null;

  const cardPositions = ["", "top-5 left-5", "top-10 left-10"];
  const zIndexes = ["z-30", "z-20", "z-10"];

  // idea chains here
  const handleLinkIdea = (cardIndex, ideaIndex, contributionId) => {
    const newLinkedIdeas = [...linkedIdeas];
    const newTextLinkedTo = [...textLinkedTo];

    // if (ideaIndex === 0) {
    //   // Toggle the link status for the user's idea
    //   if (newLinkedIdeas[cardIndex].includes(0)) {
    //     newLinkedIdeas[cardIndex] = [];
    //     newTextLinkedTo[cardIndex] = ""; // Remove the link
    //   } else {
    //     newLinkedIdeas[cardIndex] = [0];
    //     // Link to the user's own idea (you might want to use a specific ID for this)
    //     newTextLinkedTo[cardIndex] = "";
    //   }
    // } else {
    // Get the contribution ID for the clicked idea

    if (newTextLinkedTo[cardIndex] == contributionId) {
      // Remove the link
      newLinkedIdeas[cardIndex] = newLinkedIdeas[cardIndex].filter(
        (i) => i !== ideaIndex
      );
      newTextLinkedTo[cardIndex] = "";
    } else {
      // Add the link
      newLinkedIdeas[cardIndex] = [...newLinkedIdeas[cardIndex], ideaIndex];
      newTextLinkedTo[cardIndex] = contributionId;
    }
    // }

    setLinkedIdeas(newLinkedIdeas);
    setTextLinkedTo(newTextLinkedTo);
    // console.log("new text linked to: ", textLinkedTo);
  };

  // const renderChainText = (index) => {
  //   return chainText[index]
  //     .filter((text) => text !== "")
  //     .map((text, ideaIndex) => (
  //       <li key={ideaIndex} className="mb-2">
  //         <span>{text}</span>
  //       </li>
  //     ));
  // };

  const parentIdeaRef = useRef(null);
  // custom arrow
  const CustomArrow = ({ height, color }) => {
    let strokeColor;
    switch (color) {
      case 'purple':
        strokeColor = "#560C7A"; // Dark Purple
        break;
      case 'blue':
        strokeColor = "#0C437A"; // Dark Blue
        break;
      default:
        strokeColor = "#0C7A56"; // Green (default)
    }

    useEffect(() => {
      const updateLinePosition = () => {
        if (contentRef.current) {
          setContentHeight(contentRef.current.offsetHeight);
        }
      };

      const resizeObserver = new ResizeObserver(updateLinePosition);
      if (contentRef.current) {
        resizeObserver.observe(contentRef.current);
      }

      return () => {
        if (contentRef.current) {
          resizeObserver.unobserve(contentRef.current);
        }
      };
    }, []);

    return (
      <svg
        width="48"
        height={height}
        viewBox={`0 0 48 ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={`M12 0 V${height - 20} Q12 ${height - 12} 20 ${height - 12} H36`}
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d={`M30 ${height - 18} L36 ${height - 12} L30 ${height - 6}`}
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          fill="none"
        />
      </svg>
    );
  };

  const getArrowColor = (index) => {
    switch (index) {
      case 0:
        return 'green';
      case 1:
        return 'purple';
      case 2:
        return 'blue';
      default:
        return 'green';
    }
  };

  // measuring child idea height
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, []);

  return submitted ? (
    <VotingIntro
      preVote={false}
      totalParticipants={totalRounds}
      submittedParticipants={submissionCount}
    />
  ) : (
    <div className="bg-profile min-h-screen w-full flex flex-col overflow-x-hidden">
      {/* Session question mobile */}
      <div className="md:hidden mt-20 mx-5 flex flex-col justify-center items-center">
        <div className="flex-col items-center mx-auto w-full py-6 mb-4 bg-white rounded-md drop-shadow-custom">
          <h1 className="font-semibold w-full text-base text-center">
            Session Question:
          </h1>
          <p className="text-sm mt-2 text-center mx-5">{teamQuestion} </p>
        </div>
      </div>

      {/* Round and timer mobile */}
      <div className="md:hidden flex justify-between mx-5 mt-4 mb-2 text-base text-orange900 font-normal">
        <p className="">
          Round {currentRound} of {totalRounds}
        </p>

        <div>
          {dataLoaded && endTime && (
            <Timer endTime={endTime} onTimerEnd={handleTimerEnd} />
          )}
        </div>
      </div>

      {/* Round and timer medium screens */}
      <div className="mx-10">
        <div className="hidden md:flex justify-between mx-auto mt-24 text-base text-orange900 font-normal max-w-[800px]">
          <p className="">
            Round {currentRound} of {totalRounds}
          </p>

          <div>
            {dataLoaded && endTime && (
              <Timer endTime={endTime} onTimerEnd={handleTimerEnd} />
            )}
          </div>
        </div>
      </div>

      {/* Session question medium screens */}
      <div className="mx-10">
        <div className="hidden md:flex mt-4 mx-auto flex-col justify-center items-center max-w-[800px] max-h-[112px]">
          <div className="flex-col items-center mx-auto w-full py-6 mb-5 bg-white rounded-md drop-shadow-custom">
            <h1 className="font-semibold w-full text-base text-center">
              Session Question:
            </h1>
            <p className="text-sm mt-2 text-center mx-5">{teamQuestion}</p>
          </div>
        </div>
      </div>

      {/* round 1 */}
      <div className="w-full h-full flex flex-col items-center px-5 md:px-10">
        {currentRound === 1 && (
          <div className="w-full h-full items-center justify-center">
            <div className="flex flex-col items-center justify-center bg-white max-w-[600px] p-5 mx-auto rounded-xl mb-10 mt-5">
              {[0, 1, 2].map((index) => (
                <div
                  key={index}
                  className={`w-full h-full bg-${getCardColor(
                    index
                  )} px-4 py-6 rounded-xl mb-4 last:mb-0 flex flex-col`}
                  onClick={(e) => handleCardClick(index, e)}
                >
                  <h2 className="font-semibold mb-1">Idea {index + 1}</h2>

                  <div className="relative -mx-2 -mb-6 h-full">
                    {submittedIdeas[index] ? (
                      <div
                        id={`target-display-${index}`}
                        className={`flex items-center p-3 pb-6 text-sm text-left rounded-lg cursor-pointer}`}
                        onClick={(e) => handleEdit(index, e)}
                      >
                        <img
                          src="https://brainwrite-assets.s3.amazonaws.com/edit-icon.svg"
                          className="hover:cursor-pointer mr-2 flex-shrink-0"
                          alt="Edit"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <div className="flex-grow break-all overflow-wrap-anywhere">
                          {displayedIdeas[index]}
                        </div>
                      </div>
                    ) : (
                      <>
                        <textarea
                          ref={textareaRefs[index]}
                          id={`source-textarea-${index}`}
                          className={`w-full h-full flex-grow border-none rounded-lg p-3 pb-0 pr-10 default-value-bwgray text-black text-sm text-left bg-${getCardColor(
                            index
                          )} outline-none focus:ring-[1.6px] ${index === 0
                            ? "focus:ring-bwdgreen"
                            : index === 1
                              ? "focus:ring-bwdpink"
                              : "focus:ring-newdblue"
                            } focus:ring-opacity-100`}
                          placeholder={`Enter your idea ${index + 1} here...`}
                          value={ideas[index]}
                          onChange={(e) =>
                            handleIdeaChange(index, e.target.value)
                          }
                          onKeyPress={(e) => handleKeyPress(e, index)}
                          onClick={(e) => e.stopPropagation()}
                        />
                        {ideas[index].trim() !== "" && (
                          <button
                            className={`absolute top-1 right-1 p-1 bg-transparent rounded-full transition-all duration-300 ease-in-out
            hover:ring-2 hover:ring-opacity-50 ${getHoverRingColor(index)}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSubmit(index);
                            }}
                          >
                            <img
                              src={getButtonImage(index)}
                              alt="Submit"
                              className="w-6 h-6 transition duration-300 ease-in-out"
                            />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* round 2 and beyond */}
      {currentRound > 1 && (
        <div className="flex justify-center items-center mx-5 lg:mx-10">
          <div
            className={`mb-20 px-5 ${isActive ? "w-full" : ""
              } transition-all transform duration-300 ease-in-out`}
          >
            <div
              className={`relative w-full mr-5 ${isActive ? "flex flex-col items-center" : "group"
                } transition-all duration-300 ease-in-out`}
            >
              {[0, 1, 2].map((index) => {
                const isEditing = editingIndex === index;
                const isSubmitted = submittedIdeas[index];
                const isExpanded = expandedCard === index;
                const shouldRender = !expandedCard || isExpanded;

                return shouldRender ? (
                  <div
                    key={index}
                    className={`
                        ${zIndexes[index]} bg-${getCardColor(index)} 
                        w-[320px] h-[367px] md:h-[448px] rounded-lg drop-shadow-custom 
                        transition-all duration-300 ease-in-out transform
                        ${isExpanded
                        ? "-mb-5 w-full lg:max-w-[800px] max-h-[360px]"
                        : "hidden"
                      }
                        overflow-hidden
                      `}
                  >
                    {expandedCard === index && isActive ? (
                      <div className="flex flex-col pt-3 px-6 relative bg-white">
                        {currentRound > 1 && (
                          <>
                            <div
                              className={`${getHeaderColor(
                                index,
                                expandedCard === index
                              )} p-3 transition-colors duration-300 -mt-3 -mx-6 mb-2`}
                            >
                              <h2
                                className={`w-full font-semibold text-base text-left ${expandedCard === index && isActive
                                  ? "text-white"
                                  : "text-black"
                                  }`}
                              >
                                Idea {index + 1}
                              </h2>
                            </div>
                            {/* scroll area */}
                            <div className="flex flex-col items-center w-full h-full overflow-x-hidden rounded-2xl pb-32 md:pb-56 max-h-[367px] md:max-h-[448px] overflow-y-auto mt-1 scrollbar scrollbar-thumb-profile scrollbar-thumb-rounded-md scrollbar-w-1 px-2 bg-yellow-100">
                              <div className="flex flex-col flex-grow w-full max-w-[600px] p-4 rounded-lg">

                                {/* previous participants' ideas */}
                                {chainContributions[index].map(
                                  (contribution, contribIndex) => {
                                    if (contribution.isParent) {
                                      return (
                                        <div key={contribIndex}>
                                          <div
                                            ref={parentIdeaRef}
                                            className={`bg-${getCardColor(
                                              index
                                            )} flex flex-col mb-3 rounded-lg p-4 w-full h-full max-w-[600px]`}
                                          >
                                            <div className="flex flex-row justify-between relative">
                                              <h2 className="text-left pt-1 text-md md:text-base font-semibold">
                                                {contribution.userInfo?.hidden
                                                  ? contribution.alias
                                                  : contribution.userInfo
                                                    ?.fullName}
                                              </h2>
                                              <button
                                                onClick={() =>
                                                  handleLinkIdea(
                                                    index,
                                                    contribIndex,
                                                    contribution._id
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    textLinkedTo[index] ==
                                                      contribution._id
                                                      ? `https://brainwrite-assets.s3.amazonaws.com/linked-${index === 0
                                                        ? "green"
                                                        : index === 1
                                                          ? "purple"
                                                          : "blue"
                                                      }.svg`
                                                      : "https://brainwrite-assets.s3.amazonaws.com/unlinked.svg"
                                                  }
                                                  className="static w-8 h-8 top-0 right-0 lg:absolute lg:-right-20"
                                                  alt={
                                                    textLinkedTo[index] ==
                                                      contribution._id
                                                      ? "Linked"
                                                      : "Unlinked"
                                                  }
                                                />
                                              </button>
                                            </div>
                                            <div className="py-4 text-sm md:text-base w-full text-left flex-grow break-words pr-0">
                                              {contribution.ideaText}
                                            </div>
                                          </div>
                                          <div ref={contentRef} className="h-full relative">
                                            {(() => {
                                              const currentContribution = chainContributions[index].find(contribution => contribution.isParent);
                                              const hasLinkedChildIdeas = currentContribution && links[currentContribution._id] && links[currentContribution._id].length > 0;
                                              const hasUserLinkedIdea = textLinkedTo[index] === currentContribution?._id;
                                              const totalLinkedIdeas = (hasLinkedChildIdeas ? links[currentContribution._id].length : 0) + (hasUserLinkedIdea ? 1 : 0);

                                              return (
                                                <>
                                                  {totalLinkedIdeas > 1 && (
                                                    <svg
                                                      width="2"
                                                      height={contentHeight}
                                                      viewBox={`0 0 2 ${contentHeight}`}
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className={`absolute left-[7px] md:left-[27px] top-5`}
                                                      // style={{ top: `${parentIdeaRef.current ? parentIdeaRef.current.offsetHeight - 120 : 0}px` }}
                                                      preserveAspectRatio="none"
                                                    >
                                                      <line
                                                        x1="1"
                                                        y1="0"
                                                        x2="1"
                                                        y2={contentHeight}
                                                        stroke={
                                                          index === 0
                                                            ? "#0C7A56"
                                                            : index === 1
                                                              ? "#560C7A"
                                                              : "#0C437A"
                                                        }
                                                        strokeWidth="2"
                                                      />
                                                    </svg>
                                                  )}
                                                </>
                                              );
                                            })()}
                                            {/* display child ideas: */}
                                            {links[contribution._id] && links[contribution._id].map((childId, childIndex) => {
                                              const matchingContribution = chainContributions[index].find(
                                                (contrib) => contrib._id === childId
                                              );
                                              return (
                                                <div key={childId} className="relative">
                                                  <div className="absolute -left-1 md:left-4 top-0">
                                                    <CustomArrow
                                                      height={45}
                                                      color={getArrowColor(index)}
                                                    />
                                                  </div>
                                                  <div
                                                    className={`bg-${getCardColor(
                                                      index
                                                    )} flex flex-col flex-grow max-w-[600px] mb-4 rounded-lg p-4 ml-10 md:ml-20`}
                                                  >
                                                    <div className="flex flex-row justify-between">
                                                      <h3 className="text-left pt-1 text-md md:text-base font-semibold">
                                                        Child Idea
                                                      </h3>
                                                    </div>
                                                    <div
                                                      id={`target-display-${index}`}
                                                      className={`flex items-start p-2 text-sm md:text-base text-left rounded-md bg-opacity-50 cursor-pointer`}
                                                    >
                                                      <div className="flex-grow w-full break-words pr-0 md:pr-10">
                                                        {matchingContribution
                                                          ? matchingContribution.ideaText
                                                          : "Idea not found"}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                            )}
                                          </div>
                                          {/* Display user's inputted idea if it's linked to this parent */}
                                          {
                                            textLinkedTo[index] ===
                                            contribution._id &&
                                            isSubmitted &&
                                            !isEditing && (
                                              <div className="relative">
                                                <div className="absolute -left-1 md:left-4 top-0">

                                                  <CustomArrow
                                                    height={45}
                                                    color={getArrowColor(index)}
                                                  />
                                                </div>
                                                <div
                                                  className={`bg-${getCardColor(
                                                    index
                                                  )} flex flex-col flex-grow max-w-[600px] mb-8 rounded-lg p-4 ml-10 md:ml-20`}
                                                >
                                                  <div className="flex flex-row justify-between">
                                                    <h3 className="text-left pt-1 text-md md:text-base font-semibold">
                                                      Your idea
                                                    </h3>
                                                  </div>
                                                  <div
                                                    id={`target-display-${index}`}
                                                    className={`flex items-start py-2 sm:pr-2 text-sm md:text-base text-left rounded-md bg-opacity-50 cursor-pointer`}
                                                    onClick={(e) =>
                                                      handleEdit(index, e)
                                                    }
                                                  >
                                                    <img
                                                      src="https://brainwrite-assets.s3.amazonaws.com/edit-icon.svg"
                                                      className="hover:cursor-pointer mr-2 flex-shrink-0"
                                                      alt="Edit"
                                                      style={{
                                                        width: "24px",
                                                        height: "24px",
                                                      }}
                                                    />
                                                    <div className="flex-grow w-full break-words pr-0 -mr-3 md:pr-10">
                                                      {displayedIdeas[index]}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          }
                                        </div>
                                      );
                                    }
                                    return null;
                                  }
                                )}
                                {/* Display inputted text, current participant's idea */}
                                {isSubmitted && !isEditing && (
                                  <div
                                    className={` bg-${getCardColor(
                                      index
                                    )} flex flex-col flex-grow max-w-[600px] mb-8 rounded-lg p-4 ${textLinkedTo[index] != "" ? "hidden" : ""
                                      }`}
                                  >
                                    <div className="flex flex-row justify-between">
                                      <h3 className="text-left pt-1 text-md md:text-base font-semibold">
                                        Your idea
                                      </h3>
                                    </div>
                                    <div
                                      id={`target-display-${index}`}
                                      className={`flex items-start p-2 text-sm md:text-base text-left rounded-md bg-opacity-50 cursor-pointer`}
                                      onClick={(e) => handleEdit(index, e)}
                                    >
                                      <img
                                        src="https://brainwrite-assets.s3.amazonaws.com/edit-icon.svg"
                                        className="hover:cursor-pointer mr-2 flex-shrink-0"
                                        alt="Edit"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                      <div className="flex-grow w-full break-words pr-10">
                                        {displayedIdeas[index]}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        {!(isSubmitted && !isEditing) && (
                          // Textarea
                          <div className="fixed py-2 drop-shadow-customup flex flex-row w-full h-auto bg-white left-0 bottom-0">
                            <textarea
                              ref={textareaRefs[index]}
                              id={`source-textarea-${index}`}
                              className={`h-[40px] w-full mx-2 md:mx-5 flex-grow border-none rounded-full pr-14 pl-6 default-value-bwgray text-black text-base text-left bg-${getCardColor(
                                index
                              )}`}
                              placeholder="Enter your idea here..."
                              value={ideas[index]}
                              onChange={(e) =>
                                handleIdeaChange(index, e.target.value)
                              }
                              onKeyPress={(e) => handleKeyPress(e, index)}
                              onClick={(e) => e.stopPropagation()}
                            />
                            <button
                              className={`absolute top-3 right-3 md:right-6 p-1 bg-transparent rounded-full transition-all duration-300 ease-in-out
                                hover:ring-2 hover:ring-opacity-50 ${getHoverRingColor(
                                index
                              )}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSubmit(index);
                              }}
                            >
                              <img
                                src={getButtonImage(index)}
                                alt="Submit"
                                className="w-6 h-6"
                              />
                            </button>
                          </div>
                        )}

                        {/* next and done buttons, back arrow */}
                        <div
                          className={`fixed bg-white w-full bottom-0 -mx-6 drop-shadow-customup ${submittedIdeas[index] ? "" : "hidden"
                            }`}
                        >
                          <div className="flex justify-center items-center pt-4 px-3 pb-3">
                            {index > 0 ? (
                              <img
                                src={getBackArrowImage(index)}
                                alt="back arrow"
                                className="w-[16px] h-[16px] cursor-pointer absolute left-3"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleBack(index);
                                }}
                              />
                            ) : (
                              <div className="w-[16px]"></div> // Placeholder to maintain layout
                            )}

                            {completedIdeas.every(Boolean) ? (
                              <button
                                className={`bg-${getCardColor(
                                  index
                                )} text-sm text-black font-semibold w-[112px] h-[40px] rounded-[32px] -my-1 ${index === 0 ? "mr-5" : "mr-1"
                                  }`}
                                onClick={handleDone}
                              >
                                Done
                              </button>
                            ) : index < 2 ? (
                              <button
                                className={`bg-${getCardColor(
                                  index
                                )} w-[112px] h-[40px] rounded-[32px] text-sm font-semibold text-black -my-1 ${index === 0 ? "mr-5" : "mr-1"}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleNext(index);
                                }}
                              >
                                Next
                              </button>
                            ) : (
                              <div className="px-6">
                                <button
                                  data-tooltip-target="tooltip-dark"
                                  type="button"
                                  className="bg-opacity-0 text-sm font-semibold text-black cursor-not-allowed px-6"
                                >
                                  Done
                                </button>
                                <div
                                  id="tooltip-dark"
                                  role="tooltip"
                                  className="absolute z-100 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                                >
                                  Submit all ideas!
                                  <div
                                    className="tooltip-arrow"
                                    data-popper-arrow
                                  ></div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p
                        className={`text-sm text-left py-3 px-6 break-words ${submittedIdeas[index]
                          ? "text-black"
                          : "text-graybrown400"
                          }`}
                      >
                        {submittedIdeas[index]
                          ? displayedIdeas[index]
                          : "Enter your idea here..."}
                      </p>
                    )
                    }
                  </div>
                ) : null;
              })}
            </div>
          </div>
        </div>
      )
      }

      {/* progress dots */}
      {
        currentRound > 1 && expandedCard !== null && (
          <div className="w-20 mx-auto">
            <div className="-mt-8 w-full h-full flex justify-between">
              {[0, 1, 2].map((index) => {
                const isExpanded = expandedCard === index;
                const borderColor =
                  index === 0
                    ? "border-bwgreen"
                    : index === 1
                      ? "border-bwpink"
                      : "border-newblue";
                const bgColor =
                  index === 0
                    ? "bg-bwgreen"
                    : index === 1
                      ? "bg-bwpink"
                      : "bg-newblue";

                return (
                  <div
                    key={index}
                    className={`
                    border-4 rounded w-4 h-4 cursor-pointer hover:opacity-80
                    ${borderColor}
                    ${isExpanded ? bgColor : ""}
                   `}
                    onClick={() => handleDotClick(index)}
                  ></div>
                );
              })}
            </div>
          </div>
        )
      }

      {/* Submit button */}
      {
        currentRound === 1 && (
          <div className="flex justify-center mb-24">
            <button
              className={`font-semibold text-sm w-[140px] h-[40px] rounded-full ${isDisabled
                ? "border border-graybrown400 text-graybrown400 cursor-not-allowed"
                : "bg-white text-orange900 hover:text-orange500 active:ring-[1.6px] active:ring-white"
                }`}
              onClick={handleDone}
              disabled={isDisabled}
            >
              {isReady ? "Ready" : "Submit"}
            </button>
          </div>
        )
      }

      <div className="mb-20">
        {/* Scrolling was clunky so i added this div */}
      </div>
    </div >
  );
};

export default MainFlow;