/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import "flowbite";
// import io from "socket.io-client";
import { useDispatch } from "react-redux";
import { checkAuth } from "../api/index.js";
import { checkUserAuth, verificationAndAuth } from "../actions/auth.js";

import { getParticipants } from "../actions/rooms.js";
import socket from "../socket.js"; // Import the shared socket instance
import * as routes from "../constants/routeNames.js";
import ParticipantsBox from "../components/ParticipantsBox.jsx";

const serverUrl = `${process.env.REACT_APP_SERVER_BASE_URL}`;

// Replace localhost:4000 with your server URL if needed
// const socket = io(serverUrl);

const colors = [
  { light: "EBFFF8", dark: "0C7A56" },
  { light: "F8EBFF", dark: "560C7A" },
  { light: "EBFFFF", dark: "0C437A" },
];

const getInitials = (name) => {
  const names = name.split(" ");
  return names
    .map((n) => n[0])
    .join("")
    .toUpperCase();
};

const ParticipantAvatar = ({ user, size = 10 }) => {
  const colorIndex = Math.floor(Math.random() * colors.length);
  const { light, dark } = colors[colorIndex];
  const initials = getInitials(user.fullName);
  return (
    <div
      className={`w-10 h-10 rounded-full flex items-center justify-center relative`}
      style={{ backgroundColor: `#${light}`, border: `2px solid #${dark}` }}
    >
      {user.profilePhoto ? (
        <img
          src={user.profilePhoto}
          alt={user.fullName}
          className="w-full h-full rounded-full object-cover"
        />
      ) : (
        <span style={{ color: `#${dark}` }} className="text-sm font-semibold">
          {initials}
        </span>
      )}
    </div>
  );
};

const WaitingToStart = () => {
  const videoRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  const [users, setUsers] = useState([]);
  const [roomCode, setRoomCode] = useState(
    JSON.parse(localStorage.getItem("room"))?.roomCode
  );
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));

  useEffect(() => {
    import("flowbite").then((flowbite) => {
      flowbite.initPopovers();
    });

    // Join the socket room
    socket.emit("joinRoom", { roomCode });

    // Listen for userJoined event
    socket.on("userJoined", (email) => {
      console.log("USER JOINED");
      updateUsers();
    });

    // Listen for roomStarted event and navigate to /session
    socket.on("roomStarted", () => {
      navigate(routes.session);
    });

    // Cleanup on component unmount
    return () => {
      socket.off("userJoined");
      socket.off("roomStarted");
      socket.emit("leaveRoom", { roomCode });
    };
  }, [roomCode]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to play video: ", error);
      });
    }
  }, []);

  const updateUsers = async () => {
    if (user && roomCode) {
      const participants = await dispatch(getParticipants(user, roomCode));
      console.log("Participants: ", participants);
      setUsers(participants);
    }
  };

  // Fetch initial users in the room from backend
  useEffect(() => {
    if (user) {
      dispatch(verificationAndAuth(navigate));
    } else {
      navigate(routes.login);
    }
    updateUsers();
    dispatch(verificationAndAuth(user, navigate));
  }, []);

  return (
    <div className="min-h-screen w-full overflow-x-hidden flex flex-col items-center justify-center bg-profile">
      {/* <img
        src="https://brainwrite-assets.s3.amazonaws.com/start-wav.svg"
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover"
        loading="lazy"
      /> */}
      <div className="relative flex-grow h-full w-full flex flex-col items-center justify-around max-h-[90vh] md:max-h-[80vh]">
        {/* participants box*/}
        <ParticipantsBox participants={users} />

        {/* logo, waiting, your ideas, tooltip */}
        <div className="flex flex-col items-center justify-center">
          {/* <img
            src="https://brainwrite-assets.s3.amazonaws.com/logo-orange.svg"
            alt="loading graphic"
            className="w-40"
          /> */}
          <div className="w-40 h-auto flex items-center justify-center">
            <video ref={videoRef} autoPlay muted loop playsInline className="">
              <source
                src="https://brainwrite-assets.s3.amazonaws.com/waiting-animation.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>

          <h1 className="text-3xl font-normal mt-6 text-orange900">
            Waiting to start...
          </h1>
          <h2 className="text-base text-graybrown400 mt-3">
            Your ideas will be anonymous
          </h2>
          <button
            data-popover-target="popover-default"
            type="button"
            className="px-3 py-2 h-6"
          >
            <img
              src="https://brainwrite-assets.s3.amazonaws.com/info-graybrown.svg"
              alt="info icon"
              className="mt-0 mb-20 hover:cursor-pointer"
            />
          </button>
          <div
            data-popover
            id="popover-default"
            role="tooltip"
            className="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-orange200 border border-gray-200 rounded-lg drop-shadow-custom opacity-0"
          >
            <div className="px-2 py-2 bg-orange500 border-b border-white rounded-t-lg">
              <h3 className="font-semibold text-white">
                What is Brainwriting?
              </h3>
            </div>
            <div className="px-3 py-2 bg-orange200">
              <p className="text-xs text-orange900">
                Brainwriting is a method for <b>ideation</b> that is typically
                done anonymously.
                <br />
                <br />A <b>question</b> or <b>problem</b> will be posed to your
                team, and each participant will respond with their{" "}
                <b>
                  initial idea<span class="inline-block">(s)</span>
                </b>
                .
                <br />
                <br />
                Next, a series of one or more <b>passing rounds</b> allows
                participants to respond to others' ideas by building on them,
                adding to them, or being inspired by them.
                <br />
                <br />
                Lastly, everyone can <b>vote</b> on the ideas, then come
                together to discuss, <b>categorize</b>, and implement them.
              </p>
            </div>
            <div data-popper-arrow className="bg-orange200 pr-2"></div>
          </div>
        </div>

        <div className="">
          <div className="min-w-[335px] max-w-[700px] rounded-xl p-3 mx-10 border-2 border-orange500">
            <div className="flex mx-5">
              <img
                src="https://brainwrite-assets.s3.amazonaws.com/tiplightbulb-orange.svg"
                alt="tip icon"
                className="w-10 h-10 justify-center items-center"
              />
              <p className="text-left ml-3 text-sm">
                Quality ideas come from quantity. Avoid placing value judments
                on ideas, allowing for others to feel comfortable sharing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingToStart;
